import React, { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { Subheader } from "../../components/Subheader";
import Header from "../../components/Header";
import { MultiYAxisChart } from "../../containers/MultiYAxisChart";
import { useParetoStore } from "../../store/Pareto";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import MultiYAxisChartFilters from "../../containers/MultiYAxisChartFilters";

export function ParetoChart() {
    const defaultFrom = new Date();
    defaultFrom.setDate(defaultFrom.getDate() - 30);
    const defaultTo = new Date();

    const { apiGetPareto, loading, setPareto } = useParetoStore();
    const { resourceId } = useParams();

    const toast = useToast();

    useEffect(() => {
        apiGetPareto(
            Number(resourceId),
            defaultFrom.toISOString(),
            defaultTo.toISOString()
        ).catch((err) => {
            const isNotFound = err.response?.status === 404;
            setPareto([]);
            toast({
                title: isNotFound
                    ? "Nenhum dado encontrado."
                    : "Erro ao carregar dados",
                status: isNotFound ? "warning" : "error",
                duration: 5000,
                isClosable: true,
            });
        });
    }, []);

    return (
        <>
            <Header title="Diagrama de pareto" />
            <Subheader title="Pareto" />
            {loading ? <LoadingSpinner height="300px" /> : <MultiYAxisChart />}
            <MultiYAxisChartFilters />
        </>
    );
}
