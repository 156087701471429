import React from "react";
import { Box, Center, Flex, Text } from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { HamburguerMenu } from "../HamburguerMenu";
import { useAuth } from "../../hooks/useAuth";

interface HeaderProps {
    title: string;
    hasBackButton?: boolean;
}
function Header({ title, hasBackButton = true }: HeaderProps) {
    const navigate = useNavigate();
    const { user, Logout } = useAuth();

    const handleLogout = () => {
        Logout();
        navigate("/");
    };

    return (
        <Flex bg="#5D0E8B" h={74} fontSize={[14, 18]} fontWeight="bold">
            {hasBackButton && (
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    ml={5}
                    onClick={() => navigate(-1)}
                    cursor="pointer"
                    data-testid="back-button"
                >
                    <FaChevronLeft color="#FFF" fontWeight={100} size={20} />
                </Flex>
            )}
            <Center
                w="100%"
                color="white"
                as="header"
                mr={hasBackButton ? 10 : 0}
            >
                <Text fontSize={[14, 16]}>{title}</Text>
            </Center>
            <Box>{user ? <HamburguerMenu onLogout={handleLogout} /> : ""}</Box>
        </Flex>
    );
}

export default Header;
