import { Box, Center } from "@chakra-ui/react";
import React from "react";

interface SubheaderProps {
    title: string;
}
export function Subheader({ title }: SubheaderProps) {
    return (
        <Center
            bg="#E5E5E5"
            h={45}
            w="100%"
            color="#5D0E8B"
            as="header"
            fontSize={[14, 18]}
            fontWeight="bold"
        >
            <Box>{title}</Box>
        </Center>
    );
}
