import { AxiosResponse } from "axios";
import apiLink from "../../config/apiLink";
import INewState from "../../interfaces/INewState";
import IState from "../../interfaces/IState";

export interface CreateNewStateServiceProps {
    description: string;
    type: number | undefined;
    code: string;
}

export async function StateDataService(): Promise<
    AxiosResponse<IState[], any>
> {
    const response: AxiosResponse<IState[], any> = await apiLink.get<
        Array<IState>
    >(`/states`);

    return response;
}

export async function CreateNewStateService(
    payload: CreateNewStateServiceProps
): Promise<AxiosResponse<any, any>> {
    const response: AxiosResponse<IState[], any> = await apiLink
        .post<any>(`/states`, payload)
        .catch((error) => {
            return error.response;
        });

    return response;
}

export async function StatePostService(
    resourceId: string,
    data: INewState
): Promise<AxiosResponse<any, any>> {
    data.timestamp = new Date(Number(data.timestamp)).toISOString();
    const response: AxiosResponse<any, any> = await apiLink.post<any>(
        `/resources/${resourceId}/time-tracking`,
        data
    );

    return response;
}

export async function EditStateService(
    stateId: number,
    payload: CreateNewStateServiceProps
): Promise<AxiosResponse<any, any>> {
    const response: AxiosResponse<IState[], any> = await apiLink
        .put<any>(`/states/${stateId}`, payload)
        .catch((error) => {
            return error.response;
        });

    return response;
}

export async function DeleteStateService(
    stateId: number
): Promise<AxiosResponse<any, any>> {
    const response: AxiosResponse<IState[], any> = await apiLink
        .delete<any>(`/states/${stateId}`)
        .catch((error) => {
            return error.response;
        });
    return response;
}
