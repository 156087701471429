import create from "zustand";
import IMachine from "../../interfaces/IMachine";
import MachineDataService from "../../services/step-service/MachineDataService";

interface MachineStore {
    machines: IMachine[];
    addApiMachines: (stepId: number) => Promise<void>;
}
export const useMachineStore = create<MachineStore>((set) => ({
    machines: [],
    addApiMachines: async (stepId: number) => {
        const { data } = await MachineDataService(stepId);
        set((state) => ({
            ...state,
            machines: data,
        }));
    },
}));
