import create from "zustand";
import IStep from "../../interfaces/IStep";
import StepDataService from "../../services/step-service/StepDataService";

interface StepsStore {
    steps: IStep[];
    selectedStep: number;
    addApiSteps: () => Promise<void>;
    setSelectStep: (step: number) => void;
}
export const useStepsStore = create<StepsStore>((set) => ({
    steps: [],
    selectedStep: 0,
    addApiSteps: async () => {
        const response = await StepDataService();
        set((state) => ({
            ...state,
            steps: response.data,
        }));
    },

    setSelectStep: (step: number) =>
        set((state) => ({ ...state, selectedStep: step })),
}));
