import create from "zustand";
import {
    GetOeeByDay,
    IOeeByDayProps,
    IOeeProps,
} from "../../interfaces/IOeeProps";
import {
    AvailabilityOEE,
    getOeeByDay,
} from "../../services/resource-state-service/ResourceStateService";

interface OeeStore {
    oeeParams: IOeeProps;
    oeeByDay: IOeeByDayProps[];
    addApiOeeParams: (id: number) => Promise<void>;
    apiGetOeeByDay: ({
        id,
        from,
        to,
        accumulated,
    }: GetOeeByDay) => Promise<void>;
    loading: boolean;
}

export const useOeeStore = create<OeeStore>((set) => ({
    oeeParams: {} as IOeeProps,
    oeeByDay: [] as IOeeByDayProps[],
    loading: false,
    addApiOeeParams: async (id: number) => {
        set({
            loading: true,
        });
        return AvailabilityOEE(id)
            .then((response) => {
                set(() => ({
                    oeeParams: response.data,
                }));
            })
            .finally(() => {
                set({
                    loading: false,
                });
            });
    },
    apiGetOeeByDay: async ({ id, from, to, accumulated }: GetOeeByDay) => {
        set({
            loading: true,
        });

        const { data } = await getOeeByDay({
            id,
            from,
            to,
            accumulated,
        }).finally(() => {
            set({
                loading: false,
            });
        });

        set((state) => ({
            ...state,
            oeeByDay: data || [],
        }));
    },
}));
