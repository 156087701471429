import create from "zustand";
import { getPaintingCorrelation } from "../../services/correlation-service/CorrelationService";
import { getSelectInputs } from "../../services/resource-state-service/ResourceStateService";

export interface AxisProps {
    id: number;
    description: string;
}
export interface CorrelationStore {
    loading: boolean;
    correlationValues: any[];
    getCorrelationValues: (
        machineId: number,
        xColumnId: number,
        yColumnId: number,
        from: string,
        to: string
    ) => Promise<void>;
    xAxis: AxisProps[];
    yAxis: AxisProps[];
    apiGetXAxisValues: (resourceId: number, type?: string) => Promise<void>;
    apiGetYAxisValues: (resourceId: number, type?: string) => Promise<void>;
    selectedXAxis: number;
    selectedYAxis: number;
    setSelectedXAxis: (value: number) => void;
    setSelectedYAxis: (value: number) => void;
}

export const useCorrelationStore = create<CorrelationStore>((set) => ({
    correlationValues: [],
    xAxis: [],
    yAxis: [],
    loading: false,
    apiGetXAxisValues: async (resourceId: number) => {
        set({ loading: true });
        const res = await getSelectInputs(resourceId);

        set((state) => ({
            ...state,
            xAxis: res.data,
            loading: false,
        }));
    },
    apiGetYAxisValues: async (resourceId: number) => {
        set({ loading: true });
        const res = await getSelectInputs(resourceId, "number");

        set((state) => ({
            ...state,
            yAxis: res.data,
            loading: false,
        }));
    },
    selectedXAxis: 1,
    selectedYAxis: 131,
    setSelectedXAxis: (value: number) => {
        set({ loading: true });
        set((state) => ({
            ...state,
            selectedXAxis: value,
            loading: false,
        }));
    },
    setSelectedYAxis: (value: number) => {
        set({ loading: true });
        set((state) => ({
            ...state,
            selectedYAxis: value,
            loading: false,
        }));
    },

    getCorrelationValues: async (
        machineId: number,
        xColumnId: number,
        yColumnId: number,
        from: string,
        to: string
    ) => {
        set({ loading: true });
        const response = await getPaintingCorrelation(
            machineId,
            xColumnId,
            yColumnId,
            from,
            to
        ).finally(() => set({ loading: false }));

        const correlationValues = response.data;

        set((state) => ({
            ...state,
            correlationValues,
        }));
    },
}));
