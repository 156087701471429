import React, { useEffect } from "react";
import { Box, Center, Heading } from "@chakra-ui/react";
import { useTimeTrackingStore } from "../../store/TimeTrackings";
import { useOeeStore } from "../../store/Oee";
import { TimeTrackingTable } from "../TimeTrackingTable";
import { ResourceStateSubHeader } from "./ResourceStateSubHeader";

function ResourceState() {
    const timeTrackingsValues = useTimeTrackingStore(
        (state) => state.timeTrackings
    );
    const setOeeParams = useOeeStore((state) => state.addApiOeeParams);

    useEffect(() => {
        setOeeParams(timeTrackingsValues.id);
    }, [timeTrackingsValues]);

    return (
        <Box data-testid="resource-history-list" color="#787878">
            <ResourceStateSubHeader />
            <Box p={4}>
                <Center>
                    <Heading as="h2" size="xs" noOfLines={1}>
                        Histórico
                    </Heading>
                </Center>
                <TimeTrackingTable />
            </Box>
        </Box>
    );
}

export default ResourceState;
