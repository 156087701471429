import React from "react";
import { Button, Text } from "@chakra-ui/react";

interface StepCardProps {
    step: {
        id: number;
        description: string;
    };
    onClick: (stepId: number) => void;
    children: React.ReactNode;
}
export function StepCard({ onClick, step, children }: StepCardProps) {
    return (
        <Button
            minW={[70, 90]}
            minH={[70, 90]}
            whiteSpace="normal"
            fontWeight={400}
            fontSize="12px"
            p={1}
            m={0}
            onClick={() => onClick(step.id)}
            border="1px solid #A1A1A1"
            _hover={{ bgColor: "#9BF1EA" }}
            _focus={{ outline: 0, bgColor: "#9BF1EA" }}
        >
            <Text fontWeight="bold" color="#515151">
                {children}
            </Text>
        </Button>
    );
}
