import { ModalCloseButton, ModalHeader } from "@chakra-ui/react";
import React from "react";

interface ModalHeaderComponentProps {
    title: string;
}
export function ModalHeaderComponent({ title }: ModalHeaderComponentProps) {
    return (
        <ModalHeader
            bg="#5D0E8B"
            h={74}
            w="100%"
            color="white"
            as="header"
            borderBottomRadius="8px"
            fontSize="18px"
            fontWeight="400"
            borderRadius={8}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            {title}

            <ModalCloseButton />
        </ModalHeader>
    );
}
