import { Ganon } from "@finderup/ganon";
import React, { createContext, useCallback, useEffect, useState } from "react";

import { config } from "../../config/ganonConfig";

export interface IUserData {
    displayName: string | null;
    email: string;
    type: string;
}

interface AuthProviderProps {
    children: React.ReactNode;
}

export interface AuthContextData {
    user: IUserData | null;
    Login(email: string, password: string): Promise<void>;
    Logout(): void;
    loading: boolean;
}

export const AuthContext = createContext<AuthContextData>(
    {} as AuthContextData
);

const ganon = Ganon(window, config);

export function AuthProvider({ children }: AuthProviderProps) {
    const [user, setUser] = useState<IUserData | null>(null);
    const [loading, setLoading] = useState(true);

    const Login = useCallback(async (email: string, password: string) => {
        setLoading(true);
        try {
            await ganon.loginWithEmailAndPassword(email, password);
            setUser(await ganon.getLoggedUser());
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, []);

    const Logout = useCallback(async () => {
        try {
            Ganon(window).logout();
            setUser(null);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const getCurrentUser = useCallback(async () => {
        if (!user) return setUser(await ganon.getLoggedUser());
    }, []);

    useEffect((): any => {
        if (!user) return getCurrentUser().finally(() => setLoading(false));
    }, [getCurrentUser, user]);

    return (
        <AuthContext.Provider value={{ user, Login, Logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
}
