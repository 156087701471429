import { AxiosResponse } from "axios";
import apiLink from "../../config/apiLink";

interface GetPaintingCorrelationProps {
    x: string;
    y: number[];
}
export async function getPaintingCorrelation(
    machineId: number,
    xColumnId: number,
    yColumnId: number,
    from: string,
    to: string
) {
    const response: AxiosResponse<any> = await apiLink.get<
        GetPaintingCorrelationProps[]
    >(
        `/resources/${machineId}/correlation/chart/x-axis-inputs/${xColumnId}/y-axis-input/${yColumnId}?from=${from}&to=${to}`
    );

    return response;
}
