import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { ModalHeaderComponent } from "../ModalHeaderComponent";

interface ConfirmActionModalProps {
    confirmModalOpen: boolean;
    onConfirm: (id: number) => void;
    onClose: () => void;
    id: number;
    title: string;
}

function ConfirmActionModal({
    confirmModalOpen,
    onConfirm,
    onClose,
    id,
    title,
}: ConfirmActionModalProps) {
    const handleModalClose = () => {
        onClose();
    };

    const handleConfirm = (ID: number) => {
        onConfirm(ID);
        handleModalClose();
    };
    return (
        <Modal isOpen={confirmModalOpen} isCentered onClose={handleModalClose}>
            <ModalOverlay />
            <ModalContent borderRadius={8} margin={2}>
                <ModalHeaderComponent title={title} />
                <ModalBody
                    display="flex"
                    justifyContent="center"
                    marginTop={4}
                    fontSize={[14, 16]}
                    fontWeight="700"
                >
                    Você tem certeza que quer remover?
                </ModalBody>

                <ModalFooter display="flex" justifyContent="center">
                    <Button
                        display="flex"
                        justifyContent="center"
                        variant="outline"
                        width="full"
                        marginTop={8}
                        onClick={() => handleConfirm(id)}
                        border="1px solid"
                        borderColor="#0DC5B7"
                        borderRadius="8px"
                        fontSize={[12, 14]}
                        maxW={300}
                        _hover={{
                            background: "#0DC5B7",
                            color: "white",
                        }}
                        transition="0.2s ease-in-out"
                        colorScheme="green"
                    >
                        Remover
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default ConfirmActionModal;
