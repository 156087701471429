import create from "zustand";
import ITypeLoss from "../../interfaces/ITypeLoss";
import { TypeLossDataService } from "../../services/type-loss-service/TypeLossService";

interface TypeLossStore {
    typesLoss: ITypeLoss[];
    addApiTypeLoss: () => Promise<void>;
}
export const useTypeLossStore = create<TypeLossStore>((set) => ({
    typesLoss: [],
    addApiTypeLoss: async () => {
        const response = await TypeLossDataService();
        set((state) => ({
            ...state,
            typesLoss: response.data,
        }));
    },
}));
