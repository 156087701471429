import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Flex, useToast } from "@chakra-ui/react";
import {
    dateToISO,
    dateToSplitted,
    isoToSplitted,
} from "../../utils/dateFormat";
import { DateInput } from "../../components/DateInput";
import { Select } from "../../components/Select";
import { useCepStore } from "../../store/Cep";

export function GraphFilters() {
    const { resourceId } = useParams();
    const { inputs, setCepType, apiGetCepByDay, cepType } = useCepStore(
        (state) => state
    );
    const toast = useToast();
    const defaultFrom = new Date();
    defaultFrom.setDate(defaultFrom.getDate() - 7);

    const [fromDateISO, setFromDateISO] = useState(dateToISO(defaultFrom));
    const [toDateISO, setToDateISO] = useState(dateToISO(new Date()));
    const [maxToValue, setMaxToValue] = useState<string | undefined>(
        dateToSplitted(new Date())
    );

    const filterAprovedReproved = [
        { id: 1, description: "Todos", value: 1 },
        { id: 2, description: "Apenas aprovados", value: 2 },
        { id: 3, description: "Apenas reprovados", value: 3 },
    ];

    const [selectOption, setSelectOption] = useState(cepType);
    const [selectFilterAprovedReproved, setSelectFilterAprovedReproved] =
        useState(filterAprovedReproved[0].id);

    const handleMaxDate = (date: string) => {
        const actualFrom = new Date(date);
        const maxValueToDate = actualFrom;
        maxValueToDate.setDate(maxValueToDate.getDate() + 90);

        setMaxToValue(dateToSplitted(maxValueToDate));

        const maxValueISO = dateToISO(maxValueToDate);

        if (maxValueISO > dateToISO(new Date())) {
            setToDateISO(dateToISO(new Date()));
        } else {
            setToDateISO(maxValueISO);
        }
    };

    const handleChangeFromDate = (date: string) => {
        const isoDate = dateToISO(new Date(date));
        setFromDateISO(isoDate);

        handleMaxDate(date);
    };
    const handleChangeToDate = (date: string) => {
        const isoDate = dateToISO(new Date(date));
        setToDateISO(isoDate);
    };

    const handleSubmitDateFilters = () => {
        if (toDateISO < fromDateISO) {
            toast({
                isClosable: true,
                duration: 3000,
                status: "error",
                description:
                    "Filtros de datas inválidos, por favor, tente novamente.",
            });
        } else {
            // To show as label in the graph
            const toDateSplitted = isoToSplitted(toDateISO);
            const fromDateSplitted = isoToSplitted(fromDateISO);

            setCepType(selectOption);
            apiGetCepByDay(
                Number(resourceId),
                fromDateSplitted,
                toDateSplitted,
                selectOption,
                selectFilterAprovedReproved
            );
        }
    };
    const handleSelect = (id: number) => {
        setSelectOption(id);
    };
    const handleSelectAprovedReprovedFilter = (id: number) => {
        setSelectFilterAprovedReproved(id);
    };

    return (
        <Flex
            w="100%"
            alignItems="center"
            flexDir="column"
            justifyContent="center"
            mt="3rem"
            gap="1rem"
        >
            <Flex
                w={["80%", "80%", "40%"]}
                justifyContent="center"
                gap="0.5rem"
            >
                <Select
                    title="Parâmetro:"
                    data={inputs}
                    handleSelect={(id) => handleSelect(id)}
                />
            </Flex>
            <Flex
                w={["80%", "80%", "40%"]}
                justifyContent="center"
                gap="0.5rem"
            >
                <Select
                    title="Aprovação:"
                    data={filterAprovedReproved}
                    handleSelect={(id) => handleSelectAprovedReprovedFilter(id)}
                />
            </Flex>
            <Flex
                w={["80%", "80%", "40%"]}
                justifyContent="space-between"
                gap="0.5rem"
            >
                <DateInput
                    label="De dia"
                    defaultValue={fromDateISO}
                    handleChangeDate={(date) => handleChangeFromDate(date)}
                    maxValue={dateToSplitted(new Date())}
                />
                <DateInput
                    label="Até dia"
                    defaultValue={toDateISO}
                    handleChangeDate={(date) => handleChangeToDate(date)}
                    maxValue={maxToValue}
                    minValue={isoToSplitted(fromDateISO)}
                />
            </Flex>

            <Button
                onClick={handleSubmitDateFilters}
                margin={2}
                border="1px solid #0DC5B7"
                color="#515151"
                w={["80%", "80%", "40%"]}
                h="36px"
                fontSize={[12, 14]}
                _hover={{
                    backgroundColor: "#0DC5B7",
                    color: "#fff",
                }}
            >
                Atualizar
            </Button>
        </Flex>
    );
}
