import { Button } from "@chakra-ui/react";
import React from "react";

interface ActionButtonProps {
    itemId: number;
    handleOnClick: (id: number) => void;
    textColor: string;
    backgroundColor: string;
    title: string;
}
export function ActionButton({
    itemId,
    handleOnClick,
    backgroundColor,
    textColor,
    title,
}: ActionButtonProps) {
    return (
        <Button
            color={textColor}
            background={backgroundColor}
            onClick={() => handleOnClick(itemId)}
        >
            {title}
        </Button>
    );
}
