import { Flex, FlexProps, Text } from "@chakra-ui/react";
import React from "react";
import { BsArrowReturnRight } from "react-icons/bs";

interface RedirectToGraphButtonProps extends FlexProps {
    title: string;
}
export function RedirectToGraphButton({
    title,
    ...rest
}: RedirectToGraphButtonProps) {
    return (
        <Flex color="#5D0E8B" align="center" gap="2px" {...rest}>
            <Text
                textDecoration="underline"
                cursor="pointer"
                _hover={{ opacity: 0.8 }}
                fontSize={[12, 14]}
                fontWeight="bold"
            >
                {title}
            </Text>
            <BsArrowReturnRight fontWeight="bold" />
        </Flex>
    );
}
