const config = {
    development: {
        luigiUrl:
            process.env.REACT_APP_LUIGI_URL ||
            "https://luigi-stg.finderup.com.br/v1",
        linkUrl:
            process.env.REACT_APP_LINK_URL ||
            "https://link-stg.finderup.com.br",
    },
    staging: {
        luigiUrl:
            process.env.REACT_APP_LUIGI_URL ||
            "https://luigi-stg.finderup.com.br/v1",
        linkUrl:
            process.env.REACT_APP_LINK_URL ||
            "https://link-stg.finderup.com.br",
    },
    production: {
        luigiUrl:
            process.env.REACT_APP_LUIGI_URL ||
            "https://luigi.finderup.com.br/v1",
        linkUrl:
            process.env.REACT_APP_LINK_URL || "https://link.finderup.com.br",
    },
    test: {
        luigiUrl:
            process.env.REACT_APP_LUIGI_URL ||
            "https://luigi-backend-test.test.com/v1",
        linkUrl: process.env.REACT_APP_LINK_URL || "https://oee.test.com.br",
    },
};

export default config[process.env.NODE_ENV];
