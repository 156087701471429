import React, { useEffect } from "react";
import { Box, Flex, HStack, Spinner, Text } from "@chakra-ui/react";
import { useStepsStore } from "../../store/Steps";
import { useMachineStore } from "../../store/Machines";
import { StepCard } from "./StepCard";

function StepList() {
    const steps = useStepsStore((state) => state.steps);
    const addSteps = useStepsStore((state) => state.addApiSteps);
    const setSelectedStep = useStepsStore((state) => state.setSelectStep);

    useEffect(() => {
        addSteps();
    }, []);

    const addApiMachines = useMachineStore((state) => state.addApiMachines);

    const handleClick = (stepId: number) => {
        addApiMachines(stepId);
        setSelectedStep(stepId);
    };

    if (steps.length === 0) {
        return (
            <Flex
                w="full"
                h="calc(100vh)"
                alignItems="center"
                justifyContent="center"
            >
                <Spinner />
            </Flex>
        );
    }

    return (
        <Box w="100%" bgColor="#C4C4C4" pl={2} h={70} mb={10}>
            <Text
                fontWeight="bold"
                fontSize={[12, 14]}
                textAlign="start"
                color="#515151"
                mb={0}
            >
                Setores
            </Text>
            <HStack
                spacing={4}
                justify={["normal", "normal", "center"]}
                overflow="auto"
                transform="translateY(5px)"
            >
                {steps.map((step) => (
                    <StepCard onClick={handleClick} step={step} key={step.id}>
                        {step.description}
                    </StepCard>
                ))}
            </HStack>
        </Box>
    );
}

export default StepList;
