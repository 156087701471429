import React from "react";
import { Flex, Select as SelectChakra, Text } from "@chakra-ui/react";

export interface SelectDataProps {
    id?: number;
    description: string;
    value?: number;
}
interface SelectProps {
    data: SelectDataProps[];
    defaultValue?: number;
    handleSelect: (id: number) => void;
    title?: string;
}
export function Select({
    title,
    data,
    defaultValue,
    handleSelect,
}: SelectProps) {
    return (
        <Flex flexDir="column" w="100%">
            <Text
                fontWeight="700"
                lineHeight="13px"
                color="#515151"
                mb="0.2rem"
                fontSize={[12, 14]}
            >
                {title}
            </Text>

            <SelectChakra
                variant="outline"
                fontWeight="bold"
                fontSize={[12, 14]}
                focusBorderColor="#515151"
                borderColor="#515151"
                color="#515151"
                border="2px solid"
                defaultValue={defaultValue}
                onChange={(e) => handleSelect(Number(e.target.value))}
                data-testid="select"
                cursor="pointer"
            >
                {data.map((item: any) => (
                    <option value={item.id} key={item.id} data-testid="option">
                        {item.description}
                    </option>
                ))}
            </SelectChakra>
        </Flex>
    );
}
