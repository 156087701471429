import React, { useState } from "react";

import { Button, Flex, useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { DateInput } from "../../components/DateInput";
import { useParetoStore } from "../../store/Pareto";
import { splittedToISO } from "../../utils/dateFormat";

export default function MultiYAxisChartFilters() {
    const { resourceId } = useParams();
    const { apiGetPareto, setPareto } = useParetoStore();
    const defaultFrom = new Date();
    defaultFrom.setDate(defaultFrom.getDate() - 30);
    const defaultTo = new Date();
    const toast = useToast();

    const [fromDate, setFromDate] = useState(defaultFrom);
    const [toDate, setToDate] = useState(defaultTo);
    const handleSubmitDateFilters = async () => {
        await apiGetPareto(
            Number(resourceId),
            fromDate.toISOString(),
            toDate.toISOString()
        ).catch((err) => {
            const isNotFound = err.response?.status === 404;
            setPareto([]);
            toast({
                title: isNotFound
                    ? "Nenhum dado encontrado."
                    : "Erro ao carregar dados",
                status: isNotFound ? "warning" : "error",
                duration: 5000,
                isClosable: true,
            });
        });
    };

    const handleChangeFromDate = (date: string) => {
        const splittedFromDateToIso = splittedToISO(date);
        setFromDate(new Date(splittedFromDateToIso));
    };
    const handleChangeToDate = (date: string) => {
        const splittedToDateToIso = splittedToISO(date);
        setToDate(new Date(splittedToDateToIso));
    };
    return (
        <>
            <Flex w="100%" alignItems="center" justifyContent="center" mt={10}>
                <Flex
                    w={["80%", "80%", "40%"]}
                    justifyContent="space-between"
                    gap="0.5rem"
                >
                    <DateInput
                        label="De dia"
                        defaultValue={fromDate.toISOString()}
                        handleChangeDate={(date) => handleChangeFromDate(date)}
                        maxValue={defaultTo.toISOString()}
                        data-testid="date-input"
                    />
                    <DateInput
                        label="Até dia"
                        defaultValue={toDate.toISOString()}
                        handleChangeDate={(date) => handleChangeToDate(date)}
                        maxValue={defaultTo.toISOString()}
                        data-testid="date-input"
                    />
                </Flex>
            </Flex>

            <Flex w="100%" alignItems="center" justifyContent="center" mt={10}>
                <Button
                    onClick={handleSubmitDateFilters}
                    margin={2}
                    border="1px solid #0DC5B7"
                    color="#515151"
                    w={["80%", "80%", "40%"]}
                    h="36px"
                    fontSize={[12, 14]}
                    _hover={{
                        backgroundColor: "#0DC5B7",
                        color: "#fff",
                    }}
                >
                    Atualizar
                </Button>
            </Flex>
        </>
    );
}
