import { Modal, ModalContent, ModalOverlay, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { useStatesStore } from "../../store/States";
import { useTypeLossStore } from "../../store/TypeLoss";
import { ModalHeaderComponent } from "../../components/ModalHeaderComponent";
import { StateModalBody } from "./CreateStateModalBody";

interface StateModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export function StateModal({ isOpen, onClose }: StateModalProps) {
    const [isLoading, setLoading] = useState(true);

    const { addApiTypeLoss } = useTypeLossStore((state) => state);
    const {
        addApiStates,
        createApiState,
        isEditing,
        editApiState,
        isEditingPayload,
    } = useStatesStore((state) => state);

    const toast = useToast();

    useEffect(() => {
        addApiTypeLoss();
        setLoading(false);
    }, []);

    const handleSubmit = async (form: any) => {
        if (isEditing && isEditingPayload) {
            const { code, description, type } = form;

            await editApiState(isEditingPayload.id, {
                code,
                description,
                type,
            })
                .then(() => {
                    toast({
                        isClosable: true,
                        duration: 1000,
                        status: "success",
                        description: "Estado editado com sucesso!",
                    });
                })
                .catch((err) => {
                    toast({
                        isClosable: true,
                        duration: 1000,
                        status: "error",
                        description: err.message ?? "Erro ao editar estado",
                    });
                });
        } else {
            await createApiState(form)
                .then(() => {
                    toast({
                        isClosable: true,
                        duration: 1000,
                        status: "success",
                        description: "Estado criado com sucesso!",
                    });
                })
                .catch((err) => {
                    toast({
                        isClosable: true,
                        duration: 1000,
                        status: "error",
                        description: err.message ?? "Erro ao criar estado",
                    });
                });
        }
        onClose();

        addApiStates();
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            isCentered
            motionPreset="slideInBottom"
        >
            <ModalOverlay />
            <ModalContent borderRadius={8} margin={2}>
                <ModalHeaderComponent
                    title={
                        isEditing
                            ? "Editar Estado de Máquina"
                            : "Adicionar Estado de Máquina"
                    }
                />
                <StateModalBody handleSubmit={(form) => handleSubmit(form)} />
            </ModalContent>
        </Modal>
    );
}
