import { useDisclosure, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Header from "../../components/Header";
import { StateModal } from "../../containers/StateModal";
import { ManageStateTable } from "../../containers/ManageStateTable";
import { useStatesStore } from "../../store/States";
import ConfirmActionModal from "../../components/ConfirmActionModal";

export function ManageState() {
    const toast = useToast();
    const {
        addApiStates,
        states,
        isEditing,
        setIsEditing,
        setIsEditingPayload,
        isDeleting,
        isDeletingPayload,
        setIsDeleting,
        deleteApiState,
    } = useStatesStore((state) => state);

    const {
        isOpen: openCreateModal,
        onClose,
        onOpen: setOpenCreateModal,
    } = useDisclosure();

    const handleCreate = () => {
        setOpenCreateModal();
    };
    const handleEdit = () => {
        setIsEditing(!isEditing);
        setIsEditingPayload(null);
    };
    const handleDelete = async (id: number) => {
        const res = await deleteApiState(id);
        if (res?.statusCode !== 200) {
            toast({
                isClosable: true,
                duration: 5000,
                status: "error",
                description: res?.message ?? "Erro ao editar estado",
            });
        } else {
            toast({
                isClosable: true,
                duration: 5000,
                status: "success",
                description: "Estado deletado com sucesso!",
            });
        }
        addApiStates();
    };
    useEffect(() => {
        addApiStates();
    }, []);

    return (
        <>
            <Header title="Estados" />
            <ManageStateTable isAddingState={handleCreate} states={states} />
            {openCreateModal && (
                <StateModal isOpen={openCreateModal} onClose={onClose} />
            )}
            {isEditing && (
                <StateModal isOpen={isEditing} onClose={handleEdit} />
            )}
            {isDeleting && isDeletingPayload && (
                <ConfirmActionModal
                    id={isDeletingPayload.id}
                    onConfirm={(id: number) => handleDelete(id)}
                    confirmModalOpen={isDeleting}
                    title="Remover estado"
                    onClose={() => setIsDeleting(false)}
                />
            )}
        </>
    );
}
