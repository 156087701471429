import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
} from "@chakra-ui/react";

import ErrorMessage from "../../components/ErrorMessage";
import { useAuth } from "../../hooks/useAuth";

export default function LoginPage() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const { Login, user } = useAuth();

    useEffect(() => {
        if (user) {
            navigate("/home");
        }
    }, [user]);

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        Login(email, password)
            .then(() => {
                navigate("/home");
            })
            .catch(() => {
                setError("E-mail ou Senha inválidos");
            });
    };

    const handleEmail = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.currentTarget.value);
    };

    const handlePassword = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.currentTarget.value);
    };

    return (
        <Flex width="full" align="center" justifyContent="center">
            <Box
                p={8}
                m={10}
                maxWidth="500px"
                borderWidth={1}
                borderRadius={8}
                boxShadow="lg"
            >
                <Box textAlign="center">
                    <Heading>Login</Heading>
                </Box>
                <Box p={4} textAlign="left">
                    <form onSubmit={handleSubmit}>
                        {error && <ErrorMessage message={error} />}
                        <FormControl isRequired>
                            <FormLabel>Email</FormLabel>
                            <Input
                                data-testid="email"
                                type="email"
                                placeholder="test@test.com"
                                size="lg"
                                onChange={handleEmail}
                            />
                        </FormControl>
                        <FormControl isRequired mt={6}>
                            <FormLabel>Password</FormLabel>
                            <Input
                                data-testid="password"
                                type="password"
                                placeholder="********"
                                size="lg"
                                onChange={handlePassword}
                            />
                        </FormControl>
                        <Button width="full" mt={4} type="submit">
                            Logar
                        </Button>
                    </form>
                </Box>
            </Box>
        </Flex>
    );
}
