import { Box, Flex, Spacer } from "@chakra-ui/react";
import React from "react";
import { FaChevronRight } from "react-icons/fa";
import IMachine from "../../../interfaces/IMachine";
import AtualResourceStateLabel from "./AtualResourceStateLabel";

interface MachineCardProps {
    machine: IMachine;
    machineLastStateLabel: string;
    onClick: (machineId: string) => void;
}
export default function MachineCard({
    machine,
    onClick,
    machineLastStateLabel,
}: MachineCardProps) {
    return (
        <Flex
            w="90%"
            maxW="800px"
            as="button"
            p="3"
            height={58}
            rounded="md"
            fontSize={12}
            marginTop={15}
            alignItems="center"
            onClick={() => onClick(String(machine.id))}
            data-testid="machine-card"
            border="1px solid #A1A1A1"
        >
            <Box>{machine.description}</Box>
            <Spacer />
            <AtualResourceStateLabel description={machineLastStateLabel} />
            <Spacer />
            <Box>
                <FaChevronRight />
            </Box>
        </Flex>
    );
}
