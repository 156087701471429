import { Text } from "@chakra-ui/react";
import React from "react";
import {
    AreaChart,
    Area,
    Tooltip,
    ResponsiveContainer,
    CartesianGrid,
    XAxis,
    YAxis,
    Brush,
} from "recharts";
import { FilterError } from "../../components/FilterError";
import { isoToSplitted } from "../../utils/dateFormat";
import { GradientColors, useStyles } from "./styles";

interface CompleteGraphValuesProps {
    date: string;
    value: number;
}
interface CompleteGraphProps {
    values: CompleteGraphValuesProps[];
    title?: string;
    hasPercentage?: boolean;
}
export function CompleteGraph({
    values,
    title,
    hasPercentage = false,
}: CompleteGraphProps) {
    const classes = useStyles();

    if (!values.length || !values) {
        return (
            <FilterError
                title="Não foi possível carregar os dados. Verifique a configuração dos
    filtros."
            />
        );
    }

    return (
        <div className={classes.container}>
            <Text fontWeight="bold" color="#515151" ml={5}>
                {title}
            </Text>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={values}>
                    <defs>
                        <GradientColors />
                    </defs>
                    <Tooltip
                        itemStyle={{
                            color: "#fff",
                            backgroundColor: "#0A1322",
                        }}
                        contentStyle={{ backgroundColor: "#0A1322" }}
                    />
                    <CartesianGrid
                        strokeDasharray="4 4"
                        stroke="#5D0E8B"
                        opacity={0.4}
                    />
                    <XAxis
                        dataKey="date"
                        tick={{ fill: "#515151", width: 20, height: 75 }}
                        style={{ fontWeight: "bold", fontSize: 12 }}
                        stroke="#EEEEEE"
                        height={40}
                        tickFormatter={(tick: string) => {
                            const date = isoToSplitted(
                                new Date(tick).toISOString()
                            );

                            const showFormatted = date
                                .split("-")
                                .reverse()
                                .join("/");

                            return showFormatted;
                        }}
                    />
                    <YAxis
                        tick={{ fill: "#515151" }}
                        style={{ fontWeight: "bold", fontSize: 12 }}
                        tickMargin={10}
                        stroke="#EEEEEE"
                        tickFormatter={(tick) => {
                            return hasPercentage ? `${tick}%` : tick;
                        }}
                    />

                    <Area
                        dataKey="value"
                        stroke="#5D0E8B"
                        strokeWidth={2}
                        strokeOpacity={1}
                        fill="url(#colorView)"
                        dot={{ r: 4, fill: "#5D0E8B", stroke: "#5D0E8B" }}
                        isAnimationActive={false}
                    />
                    <Brush
                        dataKey="date"
                        stroke="#5D0E8B"
                        fill="white"
                        tickFormatter={(tick) => {
                            const date = isoToSplitted(
                                new Date(tick).toISOString()
                            );

                            const showFormatted = date
                                .split("-")
                                .reverse()
                                .join("/");

                            return showFormatted;
                        }}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
}
