import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HistoryPage from "../pages/history";
import LoginPage from "../pages/login";
import { Home } from "../pages/home";
import { ManageState } from "../pages/manageState";
import { OeeChart } from "../pages/oeeChart";
import { CepChart } from "../pages/cepChart";
import { useAuth } from "../hooks/useAuth";
import { CorrelationChart } from "../pages/correlationChart";
import { ParetoChart } from "../pages/paretoChart";
import { LoadingSpinner } from "../components/LoadingSpinner";

const Routing: React.FC = function async() {
    const { user, loading } = useAuth();

    if (loading) return <LoadingSpinner />;

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LoginPage />} />
                {user ? (
                    <>
                        <Route path="/home" element={<Home />} />
                        <Route path="resource" element={<HistoryPage />}>
                            <Route path=":resourceId/history" />
                        </Route>
                        <Route path="/states" element={<ManageState />} />
                        <Route
                            path="/chart/:resourceId/oee"
                            element={<OeeChart />}
                        />
                        <Route
                            path="/chart/:resourceId/cep"
                            element={<CepChart />}
                        />
                        <Route
                            path="/chart/:resourceId/correlation"
                            element={<CorrelationChart />}
                        />
                        <Route
                            path="/chart/:resourceId/pareto"
                            element={<ParetoChart />}
                        />
                        <Route path="/*" element={<Navigate to="/home" />} />
                    </>
                ) : (
                    <Route path="/*" element={<Navigate to="/" />} />
                )}
            </Routes>
        </BrowserRouter>
    );
};

export default Routing;
