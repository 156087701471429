import React, { Suspense } from "react";
import {
    ChakraProvider,
    ColorModeScript,
    Flex,
    Spinner,
} from "@chakra-ui/react";
import Routing from "./routes/routes";
import theme from "./theme";
import { AuthProvider } from "./contexts/auth";

export function App() {
    return (
        <React.StrictMode>
            <ColorModeScript />
            <ChakraProvider theme={theme}>
                <AuthProvider>
                    <Suspense
                        fallback={
                            <Flex
                                w="full"
                                h="calc(100vh)"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Spinner />
                            </Flex>
                        }
                    >
                        <Routing />
                    </Suspense>
                </AuthProvider>
            </ChakraProvider>
        </React.StrictMode>
    );
}
