import { TableContainer, Table } from "@chakra-ui/react";
import React from "react";
import { AddButton } from "../../components/AddButton";
import { DataNotFound } from "../../components/DataNotFound";
import IState from "../../interfaces/IState";
import { TableBody } from "./TableBody";

import { TableHead } from "./TableHead";

interface ManageStateTableProps {
    isAddingState: () => void;
    states: IState[];
}
export function ManageStateTable({
    isAddingState,
    states,
}: ManageStateTableProps) {
    return (
        <TableContainer>
            <Table variant="unstyled" size="lg">
                <TableHead columnNames={["Descrição", "Perda", "Impacto"]}>
                    <AddButton onPress={isAddingState} />
                </TableHead>
                {states.length ? (
                    <TableBody />
                ) : (
                    <DataNotFound title="Nenhum estado encontrado!" />
                )}
            </Table>
        </TableContainer>
    );
}
