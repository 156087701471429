import { useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { Subheader } from "../../components/Subheader";
import { ScatterChartComponent } from "../../containers/ScatterChartComponent";
import { ScatterChartFilters } from "../../containers/ScatterChartFilters";
import { AxisProps, useCorrelationStore } from "../../store/Correlation";
import { useStepsStore } from "../../store/Steps";

export function CorrelationChart() {
    const {
        selectedXAxis,
        selectedYAxis,
        xAxis,
        yAxis,
        loading,
        apiGetXAxisValues,
        apiGetYAxisValues,
        getCorrelationValues,
    } = useCorrelationStore();
    const { selectedStep } = useStepsStore();
    const toast = useToast();

    const SubheaderTitle = (type: number, options: AxisProps[]) => {
        const option = options.find((item) => item.id === type);
        return option ? option.description : options[0]?.description;
    };

    const defaultXAxis = xAxis[0]?.id ?? 6;
    const defaultYAxis = yAxis[0]?.id ?? 15;

    const defaultFrom = new Date();
    defaultFrom.setDate(defaultFrom.getDate() - 30);

    const defaultTo = new Date();

    const { resourceId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            await apiGetXAxisValues(selectedStep);
            await apiGetYAxisValues(Number(resourceId), "number");

            await getCorrelationValues(
                Number(resourceId),
                defaultXAxis,
                defaultYAxis,
                defaultFrom.toISOString(),
                defaultTo.toISOString()
            ).catch((err) => {
                toast({
                    isClosable: true,
                    duration: 1000,
                    status: "error",
                    description:
                        err?.message ?? "Erro ao buscar dados de correlação",
                });
            });
        };
        fetchData();
    }, []);

    if (!xAxis || !xAxis.length || !yAxis || !yAxis.length)
        return <LoadingSpinner />;

    return (
        <>
            <Header title="Gráfico de correlação" />
            <Subheader
                title={`${SubheaderTitle(
                    selectedXAxis,
                    xAxis
                )} x ${SubheaderTitle(selectedYAxis, yAxis)}`}
            />
            {loading ? <LoadingSpinner /> : <ScatterChartComponent />}
            <ScatterChartFilters />
        </>
    );
}
