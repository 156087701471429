import React from "react";
import {
    ResponsiveContainer,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Line,
    Bar,
    ComposedChart,
} from "recharts";
import { FilterError } from "../../components/FilterError";
import { useParetoStore } from "../../store/Pareto";

export function MultiYAxisChart() {
    const { pareto } = useParetoStore();

    if (!pareto.length)
        return (
            <FilterError
                title="Não foi possível carregar os dados. Verifique a configuração dos
        filtros."
            />
        );

    return (
        <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
                width={500}
                height={300}
                data={pareto}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="x" fontWeight="bold" />
                <YAxis yAxisId="left" />
                <YAxis yAxisId="right" orientation="right" />
                <Tooltip />
                <Bar
                    yAxisId="left"
                    type="monotone"
                    dataKey="bar"
                    fill="#0DC5B7"
                    maxBarSize={60}
                    label={{ position: "top", fontWeight: "bold" }}
                    name="Tempo"
                />
                <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="line"
                    stroke="#5D0E8B"
                    label={{
                        position: "top",
                        value: "Pareto",
                        fill: "#000",
                    }}
                    dot={{
                        stroke: "#5D0E8B",
                        strokeWidth: 2,
                        fill: "#5D0E8B",
                    }}
                    name="Perc. Acumulado"
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
}
