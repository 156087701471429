import React from "react";
import {
    CartesianGrid,
    ResponsiveContainer,
    ScatterChart,
    XAxis,
    YAxis,
    Tooltip,
    Scatter,
} from "recharts";
import { FilterError } from "../../components/FilterError";
import { useCorrelationStore } from "../../store/Correlation";

export function ScatterChartComponent() {
    const { correlationValues } = useCorrelationStore();

    if (!correlationValues.length)
        return (
            <FilterError
                title="Não foi possível carregar os dados. Verifique a configuração dos
    filtros."
            />
        );

    const typeOfCorrelation =
        typeof correlationValues[0].x === "string" ? "category" : "number";
    return (
        <ResponsiveContainer width="100%" height={400}>
            <ScatterChart
                margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                }}
            >
                <CartesianGrid />
                <XAxis
                    type={typeOfCorrelation}
                    dataKey="x"
                    name="valor x"
                    allowDuplicatedCategory={false}
                />
                <YAxis type="number" dataKey="y" name="valor y" />
                <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                <Scatter
                    name="Correlação entre itens"
                    data={correlationValues}
                    fill="#5e0e8b"
                />
            </ScatterChart>
        </ResponsiveContainer>
    );
}
