import { EditIcon } from "@chakra-ui/icons";
import * as React from "react";

import {
    Alert,
    Box,
    Checkbox,
    FormControl,
    IconButton,
    Popover,
    PopoverCloseButton,
    PopoverContent,
    PopoverTrigger,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure,
    useToast,
    VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ReactFocusLock from "react-focus-lock";
import { useParams } from "react-router-dom";
import { ActionButton } from "../../components/ActionButton";
import ConfirmActionModal from "../../components/ConfirmActionModal";
import { DataNotFound } from "../../components/DataNotFound";
import { DrawerComponent } from "../../components/DrawerComponent";
import { DeleteResourceState } from "../../services/resource-state-service/ResourceStateService";
import { useTimeTrackingStore } from "../../store/TimeTrackings";
import { dateFormat } from "../../utils/dateFormat";
import { hourFormat } from "../../utils/hourFormat";
import { messageTimeTraking } from "./messages";
import { TimeTrackingTableSidebar } from "./TimeTrackingTableBarStyle";

interface ISelectedState {
    itemId: number;
    stateId: number;
    dateTime: string;
}

export function TimeTrackingTable() {
    const [hasFuture, setHasFuture] = useState<boolean>(false);
    const { resourceId } = useParams();

    const setTimeTrackings = useTimeTrackingStore(
        (state) => state.addApiTimeTrackings
    );

    useEffect(() => {
        setTimeTrackings(resourceId as string, hasFuture);
    }, [hasFuture]);

    const toast = useToast();

    const resource = useTimeTrackingStore((state) => state.timeTrackings);
    const deleteTimeTracking = useTimeTrackingStore(
        (state) => state.deleteTimeTracking
    );
    const { timeTrackings } = resource;

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [selectedState, setSelectedState] = useState<ISelectedState | any>();

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmModalId, setConfirmModalId] = useState(0);

    const handleConfirmModalOpen = (id: number) => {
        setConfirmModalId(id);
        setConfirmModalOpen(true);
    };
    const openDrawer = (itemId: number, stateId: number, dateTime: string) => {
        setSelectedState({ itemId, stateId, dateTime });
        onOpen();
    };

    const deleteState = async (machineId: number) => {
        await DeleteResourceState(resourceId || "", String(machineId))
            .then(() => {
                deleteTimeTracking(String(machineId));
                toast({
                    isClosable: true,
                    duration: 1000,
                    render: () => (
                        <Alert p={5} bgColor="#0DC5B7">
                            Estado deletado com sucesso
                        </Alert>
                    ),
                });
            })
            .catch(() => {
                toast({
                    isClosable: true,
                    duration: 1000,
                    render: () => (
                        <Alert p={5} bgColor="#B61414" color="#fff">
                            Erro ao deletar time tracking
                        </Alert>
                    ),
                });
            });
        await setTimeTrackings(resourceId as string);
        setConfirmModalOpen(false);
    };
    return (
        <VStack>
            <FormControl
                display="flex"
                w="100%"
                justifyContent="flex-end"
                pr="16px"
            >
                <Checkbox
                    onChange={(e) => {
                        setHasFuture(e.target.checked);
                    }}
                >
                    {hasFuture
                        ? messageTimeTraking.showingFutureState
                        : messageTimeTraking.toShowFutureState}
                </Checkbox>
            </FormControl>

            <Box
                paddingBottom={10}
                display="flex"
                width="full"
                paddingX={[2, 4]}
            >
                {timeTrackings?.length ? (
                    <>
                        <TimeTrackingTableSidebar
                            timeTrackings={timeTrackings}
                        />

                        <TableContainer
                            overflowY="auto"
                            minWidth="100%"
                            paddingLeft={[2, 4, 36]}
                        >
                            <Table size="sm">
                                <Thead>
                                    <Tr>
                                        <Th textTransform="capitalize">
                                            Início
                                        </Th>
                                        <Th />
                                        <Th textTransform="capitalize">Fim</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {timeTrackings.map((item, i) => (
                                        <Tr
                                            key={item.id}
                                            color={
                                                i === 0 &&
                                                timeTrackings.length > 1
                                                    ? "#5D0E8B"
                                                    : ""
                                            }
                                        >
                                            <Td>
                                                <Box
                                                    fontWeight="700"
                                                    fontSize="14px"
                                                >
                                                    {dateFormat(item.startTime)}
                                                </Box>

                                                <Box
                                                    fontWeight="400"
                                                    fontSize="12px"
                                                >
                                                    {hourFormat(item.startTime)}
                                                </Box>
                                            </Td>
                                            <Td>-</Td>
                                            <Td>
                                                <Box
                                                    fontWeight="700"
                                                    fontSize="14px"
                                                >
                                                    {dateFormat(item.stopTime)}
                                                </Box>
                                                <Box
                                                    fontWeight="400"
                                                    fontSize="12px"
                                                >
                                                    {hourFormat(item.stopTime)}
                                                </Box>
                                            </Td>
                                            <Td>
                                                <Box
                                                    fontWeight="700"
                                                    fontSize="14px"
                                                >
                                                    {item.state.description}
                                                </Box>
                                                {`(${item.totalTime})`}
                                            </Td>
                                            <Td width="20px" bottom="-3px">
                                                <Popover
                                                    placement="right"
                                                    closeOnBlur
                                                >
                                                    <PopoverTrigger>
                                                        <IconButton
                                                            size="sm"
                                                            icon={<EditIcon />}
                                                            aria-label="tes"
                                                            data-testid="popover"
                                                        />
                                                    </PopoverTrigger>
                                                    <PopoverContent
                                                        p={5}
                                                        width="250px"
                                                    >
                                                        <ReactFocusLock>
                                                            <Box
                                                                gap={5}
                                                                display="flex"
                                                                alignItems="center"
                                                            >
                                                                <ActionButton
                                                                    itemId={
                                                                        item.id
                                                                    }
                                                                    backgroundColor="red"
                                                                    textColor="#FFF"
                                                                    title="Deletar"
                                                                    handleOnClick={() =>
                                                                        handleConfirmModalOpen(
                                                                            item.id
                                                                        )
                                                                    }
                                                                />
                                                                <ActionButton
                                                                    itemId={
                                                                        item.id
                                                                    }
                                                                    backgroundColor="#898989"
                                                                    textColor="#FFF"
                                                                    title="Editar"
                                                                    handleOnClick={() =>
                                                                        openDrawer(
                                                                            item.id,
                                                                            item
                                                                                .state
                                                                                .id,
                                                                            item.startTime
                                                                        )
                                                                    }
                                                                />
                                                            </Box>

                                                            <PopoverCloseButton />
                                                        </ReactFocusLock>
                                                    </PopoverContent>
                                                </Popover>
                                            </Td>
                                            <Box
                                                width="6px"
                                                height="50px"
                                                left="214px"
                                                top="0px"
                                                bg=""
                                            />
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>

                            {isOpen && (
                                <DrawerComponent
                                    isOpen={isOpen}
                                    onClose={onClose}
                                    resourceId={resourceId || ""}
                                    date={selectedState?.dateTime}
                                    defaultState={selectedState?.stateId}
                                    timetrackingId={selectedState?.itemId}
                                />
                            )}
                            {confirmModalOpen && (
                                <ConfirmActionModal
                                    id={confirmModalId}
                                    onConfirm={(id: number) => deleteState(id)}
                                    confirmModalOpen={confirmModalOpen}
                                    title="Remover Tempo"
                                    onClose={() => setConfirmModalOpen(false)}
                                />
                            )}
                        </TableContainer>
                    </>
                ) : (
                    <DataNotFound title="Nenhum estado encontrado!" />
                )}
            </Box>
        </VStack>
    );
}
