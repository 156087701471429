import { Button, Flex, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { DateInput } from "../../components/DateInput";
import { Select } from "../../components/Select";
import { useCorrelationStore } from "../../store/Correlation";
import {
    dateToISO,
    dateToSplitted,
    splittedToISO,
} from "../../utils/dateFormat";

export function ScatterChartFilters() {
    const defaultFrom = new Date();
    defaultFrom.setDate(defaultFrom.getDate() - 30);

    const toast = useToast();

    const [fromDate, setFromDate] = useState(defaultFrom);
    const [toDate, setToDate] = useState(new Date());

    const {
        xAxis,
        yAxis,
        setSelectedXAxis,
        setSelectedYAxis,
        getCorrelationValues,
    } = useCorrelationStore();

    const [selectXAxisFilter, setSelectXAxisFilter] = useState(1);
    const [selectYAxisFilter, setSelectYAxisFilter] = useState(131);

    const { resourceId } = useParams();

    const handleSelectXAxisParameter = (id: number) => {
        setSelectXAxisFilter(id);
    };
    const handleSelectYAxisParameter = (id: number) => {
        setSelectYAxisFilter(id);
    };

    const handleFromDate = (date: string) => {
        const splittedFromDateToIso = splittedToISO(date);
        setFromDate(new Date(splittedFromDateToIso));

        if (splittedFromDateToIso > toDate.toISOString()) {
            setFromDate(new Date(splittedFromDateToIso));
        }
    };

    const handleToDate = (date: string) => {
        const splittedToDateToIso = splittedToISO(date);
        setToDate(new Date(splittedToDateToIso));

        if (splittedToDateToIso < fromDate.toISOString()) {
            setFromDate(new Date(splittedToDateToIso));
        }
    };

    const handleSubmitDateFilters = () => {
        setSelectedXAxis(selectXAxisFilter);
        setSelectedYAxis(selectYAxisFilter);

        getCorrelationValues(
            Number(resourceId),
            selectXAxisFilter,
            selectYAxisFilter,
            fromDate.toISOString(),
            toDate.toISOString()
        ).catch((err) => {
            toast({
                isClosable: true,
                duration: 1000,
                status: "error",
                description:
                    err?.message ?? "Erro ao buscar dados de correlação",
            });
        });
    };

    return (
        <Flex
            w="100%"
            alignItems="center"
            flexDir="column"
            justifyContent="center"
            mt="3rem"
            gap="1rem"
        >
            <Flex w={["80%", "80%", "40%"]}>
                <Select
                    data={xAxis}
                    title="Parâmetro eixo X:"
                    handleSelect={(id) => handleSelectXAxisParameter(id)}
                />
            </Flex>
            <Flex w={["80%", "80%", "40%"]}>
                <Select
                    data={yAxis}
                    title="Parâmetro eixo Y:"
                    handleSelect={(id) => handleSelectYAxisParameter(id)}
                />
            </Flex>

            <Flex
                w={["80%", "80%", "40%"]}
                justifyContent="space-between"
                gap="0.5rem"
            >
                <DateInput
                    label="De dia"
                    defaultValue={fromDate.toISOString()}
                    handleChangeDate={(date) => handleFromDate(date)}
                    maxValue={dateToSplitted(new Date())}
                />
                <DateInput
                    label="Até dia"
                    defaultValue={dateToISO(toDate)}
                    handleChangeDate={(date) => handleToDate(date)}
                    maxValue={dateToSplitted(new Date())}
                />
            </Flex>
            <Button
                onClick={handleSubmitDateFilters}
                margin={2}
                border="1px solid #0DC5B7"
                color="#515151"
                w={["80%", "80%", "40%"]}
                h="36px"
                fontSize={[12, 14]}
                _hover={{
                    backgroundColor: "#0DC5B7",
                    color: "#fff",
                }}
            >
                Atualizar
            </Button>
        </Flex>
    );
}
