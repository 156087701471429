import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CompleteGraph } from "../../containers/CompleteGraph";
import Header from "../../components/Header";
import { Subheader } from "../../components/Subheader";
import { useCepStore, IInputs } from "../../store/Cep";
import "./style.css";
import { GraphFilters } from "../../containers/GraphFilters";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { dateToSplitted } from "../../utils/dateFormat";
import { useMachineStore } from "../../store/Machines";

export function CepChart() {
    const { resourceId } = useParams();
    const {
        inputs,
        cepType,
        cepByDay,
        apiGetCepByDay,
        apiGetCepInputs,
        setCepType,
    } = useCepStore((state) => state);

    const { machines } = useMachineStore((state) => state);
    const [loading, setLoading] = useState(true);
    const defaultFilterAprovedReproved = 1;

    const defaultFrom = new Date();
    defaultFrom.setDate(defaultFrom.getDate() - 7);
    const defaultFromSplitted = dateToSplitted(defaultFrom);
    const defaultToSplitted = dateToSplitted(new Date());

    useEffect(() => {
        const fetchData = async () => {
            try {
                const inputsResponse = await apiGetCepInputs(
                    Number(resourceId),
                    "number"
                );
                await apiGetCepByDay(
                    Number(resourceId),
                    defaultFromSplitted,
                    defaultToSplitted,
                    inputsResponse[0].id as number,
                    defaultFilterAprovedReproved
                );
                setCepType(inputsResponse[0].id as number);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);
    const RightTitleToGraph = (type: number, options: IInputs[]) => {
        const option = options.find((item) => item.id === type);
        return option ? option.description : options[0]?.description;
    };

    const machineTitle = () => {
        const machine = machines.find((item) => item.id === Number(resourceId));
        return machine ? machine.description : "";
    };
    if (loading) return <LoadingSpinner />;
    return (
        <>
            <Header title={`CEP - ${machineTitle()}`} />
            <Subheader
                title={`${RightTitleToGraph(cepType, inputs)} x Datas`}
            />
            <CompleteGraph values={cepByDay} />
            <GraphFilters />
        </>
    );
}
