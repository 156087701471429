import { AxiosResponse } from "axios";
import apiLink from "../../config/apiLink";

interface GetParetoProps {
    x: string;
    bar: number;
}
export async function getPareto(machineId: number, from: string, to: string) {
    const response: AxiosResponse<any> = await apiLink.get<GetParetoProps[]>(
        `/resources/${machineId}/pareto/chart?from=${from}&to=${to}`
    );

    return response;
}
