export const dateFormat = (dateString: string) => {
    return new Intl.DateTimeFormat("pt-BR", {
        day: "numeric",
        month: "2-digit",
    }).format(new Date(dateString));
};

export const dateToISO = (date: Date) => {
    return date.toISOString();
};

export const dateToSplitted = (date: Date) => {
    return date.toISOString().split("T")[0];
};

export const isoToSplitted = (isoDate: string) => {
    return isoDate.split("T")[0];
};

export const splittedToISO = (splittedDate: string) => {
    return new Date(splittedDate).toISOString();
};
