import { AxiosResponse } from "axios";
import apiLink from "../../config/apiLink";
import IMachine from "../../interfaces/IMachine";

const MachineDataService = async (
    id: number
): Promise<AxiosResponse<IMachine[], any>> => {
    const response: AxiosResponse<IMachine[], any> = await apiLink.get<
        Array<IMachine>
    >(`/steps/${id}/machines`);

    return response;
};

export default MachineDataService;
