import { extendTheme } from "@chakra-ui/react";
import "@fontsource/roboto";

const theme = extendTheme({
    fonts: {
        heading: "Roboto",
        body: "Roboto",
    },
});

export default theme;
