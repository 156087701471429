import { Flex, Spinner } from "@chakra-ui/react";
import React from "react";

interface LoadingSpinnerProps {
    height?: string;
}
export function LoadingSpinner({
    height = "calc(100vh)",
}: LoadingSpinnerProps) {
    return (
        <Flex
            w="full"
            h={height}
            alignItems="center"
            justifyContent="center"
            data-testid="loading-animation"
        >
            <Spinner />
        </Flex>
    );
}
