import React, { useState } from "react";
import { Tooltip } from "@chakra-ui/react";
import { BsInfoCircle } from "react-icons/bs";
import { MoreInfoArgs } from "./MoreInfoArgs";
import { useOeeStore } from "../../../store/Oee";

export function MoreInfoTooltip() {
    const { oeeParams } = useOeeStore();
    const { availability, oee, performance, quality } = oeeParams;

    const [tooltipOpen, setTooltipOpen] = useState(false);

    return (
        <Tooltip
            hasArrow
            label={
                <MoreInfoArgs
                    availability={availability}
                    oee={oee}
                    performance={performance}
                    quality={quality}
                />
            }
            isOpen={tooltipOpen}
            bg="white"
            color="#515151"
            placement="right"
        >
            <span>
                <BsInfoCircle
                    fontWeight="bold"
                    cursor="pointer"
                    onClick={() => setTooltipOpen(true)}
                    onMouseEnter={() => setTooltipOpen(true)}
                    onMouseLeave={() => setTooltipOpen(false)}
                    data-testid="more-info-tooltip"
                />
            </span>
        </Tooltip>
    );
}
