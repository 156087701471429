import create from "zustand";
import { IResource } from "../../interfaces/IResource";
import { ResourceStateService } from "../../services/resource-state-service/ResourceStateService";

interface TimeTrackingStore {
    timeTrackings: IResource;
    addApiTimeTrackings: (
        resourceID: string,
        hasFuture?: boolean
    ) => Promise<void>;
    deleteTimeTracking: (machineId: string) => Promise<void>;
}
export const useTimeTrackingStore = create<TimeTrackingStore>((set) => ({
    timeTrackings: {} as IResource,
    addApiTimeTrackings: async (resourceID: string, hasFuture?: boolean) =>
        ResourceStateService(resourceID, hasFuture).then((response) => {
            set((state) => ({
                ...state,
                timeTrackings: response.data,
            }));
        }),
    deleteTimeTracking: async (machineId: string) => {
        set((state) => ({
            ...state,
            machines: state.timeTrackings.timeTrackings.filter((machine) => {
                return machine.id !== Number(machineId);
            }),
        }));
    },
}));
