import React from "react";
import {
    CircularProgress,
    CircularProgressLabel,
    Flex,
    Text,
} from "@chakra-ui/react";
import { MoreInfoTooltip } from "./MoreInfoTooltip";

interface ResumedGraphProps {
    title: string;
    value: number;
    hasInfoButton?: boolean;
}
export function ResumedGraph({
    title,
    value,
    hasInfoButton,
}: ResumedGraphProps) {
    return (
        <Flex flexDir="row" alignItems="center" justify="center" align="center">
            <Flex flexDir="column" fontWeight="bold" color="#515151" gap="4px">
                <Text ml="20px">{title}</Text>
                <Flex align="center" justify="center" gap="8px">
                    <CircularProgress
                        size="72px"
                        thickness="12px"
                        color="#0DC5B7"
                        value={value}
                        capIsRound
                    >
                        <CircularProgressLabel>
                            {Number(value).toFixed(0)}%
                        </CircularProgressLabel>
                    </CircularProgress>

                    {hasInfoButton && <MoreInfoTooltip />}
                </Flex>
            </Flex>
        </Flex>
    );
}
