import React from "react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

interface HamburguerMenuProps {
    onLogout: () => void;
}
export function HamburguerMenu({ onLogout }: HamburguerMenuProps) {
    const navigate = useNavigate();

    const handleMenuClick = (path: string) => {
        navigate(path);
    };
    return (
        <Menu>
            <MenuButton
                position="absolute"
                right="5"
                top={[7, 6, 5]}
                aria-label="Options"
                color="white"
                w={[25, 30, 35]}
                h={[25, 30, 35]}
                border="1px solid white"
                borderRadius={5}
            >
                <HamburgerIcon />
            </MenuButton>
            <MenuList>
                <MenuItem onClick={() => handleMenuClick("/home")} color="#000">
                    OEE
                </MenuItem>
                <MenuItem
                    onClick={() => handleMenuClick("/states")}
                    color="#000"
                >
                    Cadastro de estados
                </MenuItem>
                <MenuItem onClick={onLogout} color="#000">
                    Logout
                </MenuItem>
            </MenuList>
        </Menu>
    );
}
