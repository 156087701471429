import React from "react";
import { Flex, Text } from "@chakra-ui/react";

interface MoreInfoArgsProps {
    availability: number;
    performance: number;
    quality: number;
    oee: number;
}
export function MoreInfoArgs({
    availability,
    performance,
    quality,
    oee,
}: MoreInfoArgsProps) {
    return (
        <Flex flexDir="column" alignItems="flex-end">
            <Text>Disponibilidade: {availability.toFixed(2)}%</Text>
            <Text>Performance: {performance.toFixed(2)}%</Text>
            <Text>Qualidade: {quality.toFixed(2)}%</Text>
            <Text fontWeight="bold">OEE: {oee.toFixed(2)}%</Text>
        </Flex>
    );
}
