import { AxiosResponse } from "axios";
import apiLink from "../../config/apiLink";
import IStep from "../../interfaces/IStep";

const StepDataService = async (): Promise<AxiosResponse<IStep[], any>> => {
    const response: AxiosResponse<IStep[], any> = await apiLink.get<
        Array<IStep>
    >("/steps?isActive=true");
    return response;
};

export default StepDataService;
