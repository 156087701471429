import React from "react";
import { Box } from "@chakra-ui/react";

interface AtualResourceStateLabelProps {
    description: string;
}

function AtualResourceStateLabel({
    description,
}: AtualResourceStateLabelProps) {
    return (
        <Box
            fontWeight={700}
            color="#5D0E8B"
            maxWidth="auto"
            margin="0 10px 0 10px"
            textTransform="uppercase"
        >
            {description}
        </Box>
    );
}

export default AtualResourceStateLabel;
