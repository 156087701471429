import React from "react";
import { Text } from "@chakra-ui/react";

interface FilterErrorProps {
    title: string;
}
export function FilterError({ title }: FilterErrorProps) {
    return (
        <Text
            w="100%"
            h="300px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            fontWeight="bold"
            overflowWrap="break-word"
            my={8}
            px={5}
            fontSize={[12, 14]}
        >
            {title}
        </Text>
    );
}
