import React, { useEffect, useState } from "react";
import { Flex, Spinner, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useMachineStore } from "../../store/Machines";
import { AtualResourceStateService } from "../../services/resource-state-service/ResourceStateService";
import MachineCard from "./MachineCard";
import { useStepsStore } from "../../store/Steps";

export default function MachineCardList() {
    const navigate = useNavigate();
    const selectedStep = useStepsStore((state) => state.selectedStep);
    const [machinesLastStates, setMachinesLastStates] = useState<string[]>([]);

    const handleClick = (machineId: string) => {
        const machineIdString = machineId;
        navigate(`/resource/${machineIdString}/history`);
    };

    const machines = useMachineStore((state) => state.machines);

    const asyncMachineState = async () => {
        const aux = await Promise.all(
            machines.map(async (item) => {
                const { data } = await AtualResourceStateService(
                    String(item.id)
                );

                const state =
                    data.timeTrackings[0]?.state?.description || "Sem estado";

                return state;
            })
        );
        setMachinesLastStates(aux);
    };

    useEffect(() => {
        if (machines.length > 0) {
            asyncMachineState();
        }
    }, [machines]);

    useEffect(() => {
        setMachinesLastStates([]);
    }, [selectedStep]);

    if (machines.length === 0 || machinesLastStates.length === 0) {
        return (
            <Flex
                w="full"
                h="calc(100vh)"
                alignItems="center"
                justifyContent="center"
                data-testid="loading-animation"
            >
                <Spinner />
            </Flex>
        );
    }

    return (
        <VStack
            spacing={4}
            align="stretch"
            w="100%"
            margin-top=".5em"
            height="425px"
            overflow="auto"
            data-testid="machine-div"
            alignItems="center"
        >
            {machines.map((machine, index) => (
                <MachineCard
                    machine={machine}
                    machineLastStateLabel={machinesLastStates[index]}
                    onClick={(id) => handleClick(id)}
                    key={machine.id}
                />
            ))}
        </VStack>
    );
}
