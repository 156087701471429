import { Button } from "@chakra-ui/react";
import React from "react";
import { FaPlus } from "react-icons/fa";

interface AddButtonProps {
    onPress: () => void;
}
export function AddButton({ onPress }: AddButtonProps) {
    return (
        <Button
            boxSizing="border-box"
            color="#000"
            bgColor="#0DC5B7"
            _hover={{ bgColor: "#0DC5C9" }}
            onClick={onPress}
            borderRadius="8px"
            w={30}
            m={1}
            boxShadow="0px 2px 3px rgba(0, 0, 0, 0.7)"
        >
            <FaPlus size={14} />
        </Button>
    );
}
