import create from "zustand";
import { ICepProps } from "../../interfaces/ICepProps";
import {
    getCepByDay,
    getSelectInputs,
} from "../../services/resource-state-service/ResourceStateService";

export interface IInputs {
    id?: number;
    description: string;
    value?: number;
}
interface CepStore {
    cepByDay: ICepProps[];
    cepType: number;
    inputs: IInputs[];
    apiGetCepByDay: (
        id: number,
        from: string,
        to: string,
        atenuation: number,
        selectFilterAprovedReproved: number
    ) => Promise<void>;
    apiGetCepInputs: (resourceId: number, type?: string) => Promise<IInputs[]>;
    setCepType: (cepType: number) => void;
}

export const useCepStore = create<CepStore>((set) => ({
    cepByDay: [] as ICepProps[],
    cepType: 0,
    inputs: [],
    apiGetCepByDay: async (
        id: number,
        from: string,
        to: string,
        atenuation: number,
        selectFilterAprovedReproved = 1
    ) => {
        let filterAprovedReproved;
        if (selectFilterAprovedReproved === 1) filterAprovedReproved = null;
        else if (selectFilterAprovedReproved === 2)
            filterAprovedReproved = true;
        else filterAprovedReproved = false;
        const { data } = await getCepByDay(
            id,
            atenuation,
            from,
            to,
            filterAprovedReproved
        );
        set((state) => ({
            ...state,
            cepByDay: data,
        }));
    },
    apiGetCepInputs: async (resourceId: number, type?: string) => {
        const { data } = await getSelectInputs(resourceId, type);
        set((state) => ({
            ...state,
            inputs: data,
        }));

        return data;
    },
    setCepType: (cepType: number) => {
        set((state) => ({
            ...state,
            cepType,
        }));
    },
}));
