import React from "react";
import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles(() => ({
    container: {
        color: "#fff",
        backgroundColor: "rgb(255, 255, 255)",
        padding: "1rem",
        transition: "0.3s ease-in-out",
        width: "100%",
        height: "270px",
    },
}));

/** Add gradient to graph */
export function GradientColors() {
    return (
        <linearGradient id="colorView" x1="0" y1="0" x2="0" y2="1">
            <stop offset="30%" stopColor="#5D0E8B" stopOpacity={0.4} />
            <stop offset="75%" stopColor="#5D0E8B" stopOpacity={0.1} />
            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.2} />
        </linearGradient>
    );
}
