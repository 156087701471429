import { EditIcon } from "@chakra-ui/icons";
import {
    Box,
    IconButton,
    Popover,
    PopoverCloseButton,
    PopoverContent,
    PopoverTrigger,
} from "@chakra-ui/react";
import React from "react";
import IState from "../../../../interfaces/IState";
import { useStatesStore } from "../../../../store/States";
import { ActionButton } from "../../../../components/ActionButton";

interface StateMenuOptionsProps {
    actualState: IState;
}
export function StateMenuOptions({ actualState }: StateMenuOptionsProps) {
    const {
        setIsEditing,
        isEditing,
        setIsEditingPayload,
        setIsDeletingPayload,
        isDeleting,
        setIsDeleting,
    } = useStatesStore((state) => state);

    const handleEdit = (state: IState) => {
        setIsEditing(!isEditing);
        setIsEditingPayload(state);
    };
    const handleDelete = (state: IState) => {
        setIsDeleting(!isDeleting);
        setIsDeletingPayload(state);
    };

    return (
        <Popover>
            <PopoverTrigger>
                <IconButton aria-label="Edit button" icon={<EditIcon />} />
            </PopoverTrigger>
            <PopoverContent w="250px">
                <Box p={4} gap={2} display="flex" justifyContent="center">
                    <ActionButton
                        itemId={actualState.id}
                        backgroundColor="red"
                        textColor="#FFF"
                        title="Deletar"
                        handleOnClick={() => handleDelete(actualState)}
                    />
                    <ActionButton
                        itemId={actualState.id}
                        backgroundColor="green"
                        textColor="#FFF"
                        title="Editar"
                        handleOnClick={() => handleEdit(actualState)}
                    />
                </Box>
                <PopoverCloseButton />
            </PopoverContent>
        </Popover>
    );
}
