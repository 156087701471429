import React, { useEffect, useState } from "react";
import {
    Alert,
    Box,
    Button,
    Center,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    FormControl,
    FormLabel,
    Select,
    useToast,
} from "@chakra-ui/react";
// import AddNewState from "../../AddNewState";
import DatePicker from "react-datepicker";
import { useStatesStore } from "../../store/States";
import { updateResourceState } from "../../services/resource-state-service/ResourceStateService";
import { StatePostService } from "../../services/state-service/StateDataService";
import { useTimeTrackingStore } from "../../store/TimeTrackings";
import INewState from "../../interfaces/INewState";
import "./date-picker.css";

interface DrawerProps {
    isOpen: boolean;
    onClose: () => void;
    date: string;
    resourceId: string;
    defaultState: number;
    timetrackingId: number | undefined;
}

interface IDate {
    date: Date;
    hour: Date;
}

interface IUpdatedState {
    state: number;
    userId: number;
    timestamp: string;
}

export function DrawerComponent({
    isOpen,
    onClose,
    resourceId,
    date,
    defaultState,
    timetrackingId,
}: DrawerProps) {
    const [onChangeDate, setOnChangeDate] = useState<Date | null>(null);
    const [newState, setNewState] = useState<INewState>({
        resourceId,
        stateId: defaultState,
        timestamp: new Date(date).getTime().toString(),
        userId: 1,
    });
    const [updateDate, setUpdateDate] = useState<IDate>({
        date: new Date(date),
        hour: new Date(date),
    });

    const states = useStatesStore((state) => state.states);
    const getStates = useStatesStore((state) => state.addApiStates);
    const addApiTimeTrackings = useTimeTrackingStore(
        (state) => state.addApiTimeTrackings
    );
    const toast = useToast();

    const updateState = async () => {
        onClose();

        let data: any = null;
        try {
            if (timetrackingId) {
                const updatedState: IUpdatedState = {
                    state: newState.stateId,
                    userId: newState.userId,
                    timestamp: new Date(onChangeDate as Date)
                        .getTime()
                        .toString(),
                };
                data = await updateResourceState(
                    resourceId,
                    updatedState,
                    timetrackingId
                );
            } else {
                data = await StatePostService(resourceId, newState);
            }

            if (data.status === 201 || data.status === 200) {
                toast({
                    isClosable: true,
                    duration: 3000,
                    render: () => (
                        <Alert p={5} bgColor="#0DC5B7" color="white">
                            Status atualizado com sucesso!
                        </Alert>
                    ),
                });
                addApiTimeTrackings(resourceId as string);
            }
        } catch (error: any) {
            toast({
                isClosable: true,
                duration: 3000,
                render: () => (
                    <Alert p={5} bgColor="#B61414" color="white">
                        Código de erro: {error.response.data.statusCode}
                        <br />
                        {error.response.data.message}
                    </Alert>
                ),
            });
        }
    };
    const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const stateId = parseInt(e.target.value, 10);

        setNewState({ ...newState, stateId });
    };
    useEffect(() => {
        getStates();
    }, []);

    useEffect(() => {
        const formattedDate = new Date(
            updateDate.date.getFullYear(),
            updateDate.date.getMonth(),
            updateDate.date.getDate(),
            updateDate.hour.getHours(),
            updateDate.hour.getMinutes(),
            updateDate.hour.getSeconds()
        );

        setOnChangeDate(formattedDate);
        setNewState({
            ...newState,
            timestamp: formattedDate.getTime().toString(),
        });
    }, [updateDate]);
    return (
        <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay bg="blackAlpha.100" backdropFilter="blur(1px) " />

            <DrawerContent>
                <DrawerHeader
                    bg="#5D0E8B"
                    h={74}
                    w="100%"
                    color="white"
                    as="header"
                    borderBottomRadius="8px"
                    fontSize="18px"
                    fontWeight="400"
                >
                    <Center>
                        <DrawerCloseButton position="initial" />
                        {timetrackingId
                            ? "Editar Status de Máquinas"
                            : "Atualizar Status de Máquinas"}
                    </Center>
                </DrawerHeader>
                <Box
                    p={5}
                    m={1}
                    borderRadius={8}
                    bgColor="#00C3B3"
                    fontWeight={700}
                    data-testid="add-new-state"
                >
                    <FormControl>
                        <FormLabel as="legend">Data e hora atual</FormLabel>
                        <DatePicker
                            selected={onChangeDate}
                            showTimeSelect
                            dateFormat="dd/MM/yyyy HH:mm"
                            onSelect={(e: Date) => {
                                setUpdateDate({ ...updateDate, date: e });
                            }}
                            onChange={(e: Date) => {
                                setUpdateDate({ ...updateDate, hour: e });
                            }}
                        />
                    </FormControl>
                    {states.length > 0 && (
                        <FormControl mt={3}>
                            <FormLabel as="legend">Estado da Máquina</FormLabel>
                            <Select
                                bg="white"
                                onChange={handleSelect}
                                placeholder="Selecione um estado"
                                defaultValue={defaultState}
                            >
                                {states.map((state) => (
                                    <option
                                        key={state.id}
                                        value={state.id}
                                        defaultValue={defaultState}
                                    >
                                        {state.code} - {state.description}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    <Button
                        bg="#5D0E8B"
                        color="#fff"
                        width="full"
                        mt={3}
                        onClick={updateState}
                        data-testid="update-state"
                    >
                        Atualizar
                    </Button>
                </Box>
                <DrawerBody />
            </DrawerContent>
        </Drawer>
    );
}
