import React from "react";
import { Text } from "@chakra-ui/react";

interface DataNotFoundProps {
    title: string;
}
export function DataNotFound({ title }: DataNotFoundProps) {
    return (
        <Text
            w="100%"
            textAlign="center"
            justifyContent="center"
            fontWeight="bold"
            my={4}
        >
            {title}
        </Text>
    );
}
