/* eslint-disable react/require-default-props */
import { Tr, Thead, Th, Box } from "@chakra-ui/react";
import React from "react";

interface TableHeadProps {
    columnNames: string[];
    children?: React.ReactNode;
}
export function TableHead({ columnNames, children }: TableHeadProps) {
    return (
        <Thead bgColor="gray.300">
            <Tr fontWeight="extrabold">
                {columnNames.map((columnName) => (
                    <Th
                        fontSize={[12, 14]}
                        p={1}
                        textAlign="center"
                        textTransform="none"
                        key={columnName}
                    >
                        {columnName}
                    </Th>
                ))}
                <Box
                    mr={15}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="full"
                >
                    {children}
                </Box>
            </Tr>
        </Thead>
    );
}
