import { AxiosResponse } from "axios";
import apiLink from "../../config/apiLink";
import ITypeLoss from "../../interfaces/ITypeLoss";

export async function TypeLossDataService(): Promise<
    AxiosResponse<ITypeLoss[], any>
> {
    const response: AxiosResponse<ITypeLoss[], any> = await apiLink.get<
        Array<ITypeLoss>
    >(`/type-loss`);

    return response;
}
