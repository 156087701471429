import create from "zustand";
import IState from "../../interfaces/IState";
import {
    CreateNewStateService,
    CreateNewStateServiceProps,
    DeleteStateService,
    EditStateService,
    StateDataService,
} from "../../services/state-service/StateDataService";

interface StatesStore {
    states: IState[];
    isEditing: boolean;
    isEditingPayload: IState | null;
    addApiStates: () => Promise<void>;
    editApiState: (
        stateId: number,
        payload: CreateNewStateServiceProps
    ) => Promise<void>;
    deleteApiState: (stateId: number) => Promise<any>;
    setIsEditing: (isEditing: boolean) => void;
    setIsEditingPayload: (isEditingPayload: IState | null) => void;
    createApiState: (payload: CreateNewStateServiceProps) => Promise<void>;
    setIsDeleting: (isDeleting: boolean) => void;
    isDeleting: boolean;
    isDeletingPayload: IState | null;
    setIsDeletingPayload: (isDeletingPayload: IState | null) => void;
}
export const useStatesStore = create<StatesStore>((set) => ({
    states: [],
    isEditing: false,
    isEditingPayload: null,
    isDeleting: false,
    isDeletingPayload: null,
    addApiStates: async () =>
        StateDataService().then((response) => {
            set((state) => ({
                ...state,
                states: response.data,
            }));
        }),
    editApiState: async (
        stateId: number,
        payload: CreateNewStateServiceProps
    ) => {
        const api = await EditStateService(stateId, payload);
        return api.data;
    },
    deleteApiState: async (stateId: number) => {
        const api = await DeleteStateService(stateId);
        return api.data;
    },
    setIsEditing: (isEditing: boolean) =>
        set((state) => ({ ...state, isEditing })),
    setIsEditingPayload: (isEditingPayload: IState | null) => {
        set((state) => ({ ...state, isEditingPayload }));
    },
    createApiState: async (payload: CreateNewStateServiceProps) => {
        const api = await CreateNewStateService(payload);
        return api.data;
    },
    setIsDeleting: (isDeleting: boolean) =>
        set((state) => ({ ...state, isDeleting })),
    setIsDeletingPayload: (isDeletingPayload: IState | null) => {
        set((state) => ({ ...state, isDeletingPayload }));
    },
}));
