import React from "react";
import { Flex } from "@chakra-ui/react";

import StepList from "../containers/StepCardList";

import { ReactComponent as MptLogo } from "../assets/mpt-logo.svg";
import MachineCardList from "../containers/MachineCardList";
import version from "../../package.json";
import { useStepsStore } from "../store/Steps";
import Header from "../components/Header";

export function Home() {
    const selectedStep = useStepsStore((state) => state.selectedStep);

    return (
        <>
            <Header title="OEE" hasBackButton={false} />
            <Flex
                data-testid="content"
                h="87vh"
                p={0}
                flexDir="column"
                gap="20px"
            >
                <StepList data-testid="stepList" />

                {selectedStep ? <MachineCardList /> : null}

                <Flex
                    position="absolute"
                    bottom={0}
                    alignItems="center"
                    justifyContent="center"
                    w="100%"
                >
                    <MptLogo title="mpt-logo" />
                    <span>v{version.version}</span>
                </Flex>
            </Flex>
        </>
    );
}
