import { AxiosResponse } from "axios";
import apiLink from "../../config/apiLink";
import { ICepProps, ICEPSelectInputProps } from "../../interfaces/ICepProps";
import {
    GetOeeByDay,
    IOeeByDayProps,
    IOeeProps,
} from "../../interfaces/IOeeProps";

import { IResource } from "../../interfaces/IResource";
import IUpdatedState from "../../interfaces/IUpdatedState";

const baseUrl = `${process.env.REACT_APP_LINK_URL}/resources`;

export async function ResourceStateService(
    id: string,
    onlyFuture?: boolean
): Promise<AxiosResponse<IResource, any>> {
    const url = new URL(`${baseUrl}/${id}/time-tracking`);
    const params = new URLSearchParams(url.search);

    if (onlyFuture) {
        params.set("onlyFuture", "true");
    }

    url.search = params.toString();

    try {
        const response: AxiosResponse<IResource, any> =
            await apiLink.get<IResource>(url.toString());

        return response;
    } catch (error) {
        console.error(`Request Error: ${error}`);
        throw new Error(`Request Error: ${error}`);
    }
}

export async function AtualResourceStateService(
    id: string
): Promise<AxiosResponse<IResource, any>> {
    const url = new URL(`${baseUrl}/${id}/time-tracking`);
    const params = new URLSearchParams(url.search);

    if (id) {
        params.set("page", "1");
        params.set("limit", "1");
    }

    url.search = params.toString();

    try {
        console.log("URL completa:", url.toString());
        const response: AxiosResponse<IResource, any> =
            await apiLink.get<IResource>(url.toString());

        return response;
    } catch (error) {
        console.error(`Request Error: ${error}`);
        throw new Error(`Request Error: ${error}`);
    }
}

export async function DeleteResourceState(
    resourceId: string,
    id: string
): Promise<AxiosResponse<any>> {
    const url = new URL(`${baseUrl}/${resourceId}/time-tracking/${id}`);
    try {
        const response: AxiosResponse<any> = await apiLink.delete<IResource>(
            url.toString()
        );
        return response;
    } catch (error) {
        console.error(`Request Error: ${error}`);
        throw new Error(`Request Error: ${error}`);
    }
}

export async function updateResourceState(
    resourceId: string,
    data: IUpdatedState,
    id: number
): Promise<AxiosResponse<any, any>> {
    const url = new URL(`${baseUrl}/${resourceId}/time-tracking/${id}`);
    data.timestamp = new Date(Number(data.timestamp)).toISOString();
    try {
        const response: AxiosResponse<any> = await apiLink.put<any>(
            url.toString(),
            data
        );
        return response;
    } catch (error) {
        console.error(`Request Error: ${error}`);
        throw new Error(`Request Error: ${error}`);
    }
}

export async function AvailabilityOEE(
    id: number
): Promise<AxiosResponse<IOeeProps, any>> {
    const url = new URL(`${baseUrl}/${id}/oee`);

    try {
        const response: AxiosResponse<IOeeProps, any> =
            await apiLink.get<IOeeProps>(url.toString());
        return response;
    } catch (error) {
        console.error(`Request Error: ${error}`);
        throw new Error(`Request Error: ${error}`);
    }
}

export async function getOeeByDay({
    id,
    from,
    to,
    accumulated,
}: GetOeeByDay): Promise<AxiosResponse<IOeeByDayProps[], any>> {
    const defaultFrom = new Date();
    defaultFrom.setDate(defaultFrom.getDate() - 30);
    const defaultTo = new Date().toISOString();

    const fromDate = from || defaultFrom.toISOString();
    const toDate = to || defaultTo;
    const accumulatedParam = !!accumulated;

    const url = new URL(`${baseUrl}/${id}/oee/chart`);
    const params = new URLSearchParams(url.search);

    params.set("from", `${fromDate}`);
    params.set("to", `${toDate}`);
    params.set("accumulated", `${accumulatedParam}`);

    url.search = params.toString();

    try {
        const response: AxiosResponse<IOeeByDayProps[], any> =
            await apiLink.get<IOeeByDayProps[]>(url.toString());

        return response;
    } catch (error) {
        console.error(`Request Error: ${error}`);
        throw new Error(`Request Error: ${error}`);
    }
}
export async function getCepByDay(
    id: number,
    atenuation: number,
    from: string,
    to: string,
    aprovedOrReprovedFilter: boolean | null
): Promise<AxiosResponse<ICepProps[], any>> {
    const url = new URL(`${baseUrl}/${id}/inputs/${atenuation}`);
    const params = new URLSearchParams(url.search);
    const hasAprovedOrReprovedFilter =
        aprovedOrReprovedFilter === true || aprovedOrReprovedFilter === false;

    params.set("from", `${from}`);
    params.set("to", `${to}`);

    if (hasAprovedOrReprovedFilter) {
        params.set("isApproved", `${aprovedOrReprovedFilter}`);
    }

    url.search = params.toString();

    try {
        const response: AxiosResponse<ICepProps[], any> = await apiLink.get<
            ICepProps[]
        >(url.toString());

        return response;
    } catch (error) {
        console.error(`Request Error: ${error}`);
        throw new Error(`Request Error: ${error}`);
    }
}

export async function getSelectInputs(
    id: number,
    type?: string
): Promise<AxiosResponse<any>> {
    const url = new URL(`${baseUrl}/${id}/inputs`);
    const params = new URLSearchParams(url.search);

    if (type) {
        params.set("type", `${type}`);
    }

    url.search = params.toString();

    try {
        const response: AxiosResponse<any> = await apiLink.get<
            ICEPSelectInputProps[]
        >(url.toString());

        return response;
    } catch (error) {
        console.error(`Request Error: ${error}`);
        throw new Error(`Request Error: ${error}`);
    }
}
