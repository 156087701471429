import React from "react";
import { Flex } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { ResumedGraph } from "../../ResumedGraph";
import { useOeeStore } from "../../../store/Oee";
import { RedirectToGraphButton } from "../RedirectToGraphButton";

export function ResourceStateSubHeader() {
    const { oeeParams } = useOeeStore();
    const { oee } = oeeParams;
    const { resourceId } = useParams();
    const navigate = useNavigate();

    const handleRedirectTo = (path: string) => {
        navigate(path);
    };
    return (
        <Flex
            backgroundColor="#CCCCCC"
            w="full"
            alignItems="center"
            justifyContent="center"
            py={5}
        >
            <ResumedGraph title="OEE" value={oee} hasInfoButton />

            <Flex flexDir="column" ml="20px" justify="end" align="end">
                <RedirectToGraphButton
                    title="Ver gráfico de OEE"
                    onClick={() => handleRedirectTo(`/chart/${resourceId}/oee`)}
                />
                <RedirectToGraphButton
                    title="Ver gráfico de CEP"
                    onClick={() => handleRedirectTo(`/chart/${resourceId}/cep`)}
                />
                <RedirectToGraphButton
                    title="Ver gráfico de correlação"
                    onClick={() =>
                        handleRedirectTo(`/chart/${resourceId}/correlation`)
                    }
                />
                <RedirectToGraphButton
                    title="Ver diagrama de pareto"
                    onClick={() =>
                        handleRedirectTo(`/chart/${resourceId}/pareto`)
                    }
                />
            </Flex>
        </Flex>
    );
}
