import React from "react";
import { Flex, Input, Text } from "@chakra-ui/react";
import {
    dateToSplitted,
    isoToSplitted,
    splittedToISO,
} from "../../utils/dateFormat";

interface DateInputProps {
    label?: string;
    defaultValue: string;
    handleChangeDate: (date: string) => void;
    maxValue?: string;
    minValue?: string;
}

export function DateInput({
    label,
    defaultValue,
    handleChangeDate,
    maxValue,
    minValue,
}: DateInputProps) {
    const defaultDateSplit = isoToSplitted(defaultValue);
    let max = maxValue;

    if (maxValue && splittedToISO(maxValue) > new Date().toISOString()) {
        max = dateToSplitted(new Date());
    }

    return (
        <Flex flexDir="column" w="50%">
            {label && (
                <Text fontSize={[12, 14]} color="#515151" fontWeight="bold">
                    {label}
                </Text>
            )}

            <Flex borderRadius="8px" border="1px solid" borderColor="#515151">
                <Input
                    type="date"
                    defaultValue={defaultDateSplit}
                    value={defaultDateSplit}
                    onChange={(e) => handleChangeDate(e.target.value)}
                    fontSize={[12, 14]}
                    color="#515151"
                    data-testid="date-input"
                    max={max}
                    min={minValue}
                    cursor="pointer"
                />
            </Flex>
        </Flex>
    );
}
