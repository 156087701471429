import { Button, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { DateInput } from "../../components/DateInput";
import { Select } from "../../components/Select";
import { useOeeStore } from "../../store/Oee";
import { splittedToISO } from "../../utils/dateFormat";

export function OeeFilters() {
    const [accumulated, setAccumulated] = useState(0);
    const { apiGetOeeByDay } = useOeeStore();
    const { resourceId } = useParams();

    const defaultFromDate = new Date();
    defaultFromDate.setDate(defaultFromDate.getDate() - 30);

    const [fromDateISO, setFromDateISO] = useState(
        defaultFromDate.toISOString()
    );
    const [toDateISO, setToDateISO] = useState(new Date().toISOString());

    const handleChangeFromDate = (date: string) => {
        setFromDateISO(splittedToISO(date));
    };

    const handleChangeToDate = (date: string) => {
        setToDateISO(splittedToISO(date));
    };

    const handleSubmit = () => {
        apiGetOeeByDay({
            id: Number(resourceId),
            from: fromDateISO,
            to: toDateISO,
            accumulated,
        });
    };

    return (
        <Flex
            w="100%"
            alignItems="center"
            flexDir="column"
            justifyContent="center"
            mt="3rem"
            gap="1rem"
        >
            <Flex
                w={["80%", "80%", "40%"]}
                justifyContent="center"
                gap="0.5rem"
            >
                <Select
                    title="Consolidado"
                    data={[
                        { id: 0, description: "Não", value: 0 },
                        { id: 1, description: "Sim", value: 1 },
                    ]}
                    handleSelect={(value) => setAccumulated(value)}
                />
            </Flex>
            <Flex
                w={["80%", "80%", "40%"]}
                justifyContent="space-between"
                gap="0.5rem"
            >
                <DateInput
                    label="De dia"
                    defaultValue={fromDateISO}
                    handleChangeDate={(date) => handleChangeFromDate(date)}
                />
                <DateInput
                    label="Até dia"
                    defaultValue={toDateISO}
                    handleChangeDate={(date) => handleChangeToDate(date)}
                />
            </Flex>
            <Button
                onClick={handleSubmit}
                margin={2}
                border="1px solid #0DC5B7"
                color="#515151"
                w={["80%", "80%", "40%"]}
                h="36px"
                fontSize={[12, 14]}
                _hover={{
                    backgroundColor: "#0DC5B7",
                    color: "#fff",
                }}
            >
                Atualizar
            </Button>
        </Flex>
    );
}
