import { Tbody, Tr, Td } from "@chakra-ui/react";
import React from "react";
import { useStatesStore } from "../../../store/States";
import { StateMenuOptions } from "./StateMenuOptions";

export function TableBody() {
    const { states } = useStatesStore((state) => state);

    return (
        <Tbody>
            {states.map((state) => (
                <Tr key={state.id}>
                    <Td
                        fontSize={[12, 14]}
                        textAlign="center"
                        whiteSpace="break-spaces"
                        p={2}
                        w="35%"
                    >
                        {state.code} - {state.description}
                    </Td>
                    <Td
                        fontSize={[12, 14]}
                        textAlign="center"
                        whiteSpace="break-spaces"
                        p={2}
                        w="33%"
                    >
                        {state.loss}
                    </Td>
                    <Td
                        fontSize={[12, 14]}
                        textAlign="center"
                        whiteSpace="break-spaces"
                        p={2}
                        w="33%"
                    >
                        {state.impact}
                    </Td>
                    <Td padding={2} textAlign="center">
                        <StateMenuOptions actualState={state} />
                    </Td>
                </Tr>
            ))}
        </Tbody>
    );
}
