import axios, { AxiosInstance } from "axios";
import config from "./urlConfig";

const baseUrl: string | undefined = config.linkUrl;

const apiLink: AxiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        "Content-type": "application/json",
    },
});

export default apiLink;
