import React from "react";
import { Box } from "@chakra-ui/react";
import { ITimeTracking } from "../../../interfaces/IResource";

interface TimeTrackingTableSidebarProps {
    timeTrackings: ITimeTracking[];
}

export function TimeTrackingTableSidebar({
    timeTrackings,
}: TimeTrackingTableSidebarProps) {
    return (
        <Box
            border={0}
            justifyContent="center"
            display="flex"
            alignItems="center"
            textAlign="center"
            flexDirection="column"
            marginTop="10px"
        >
            {timeTrackings.map((timeTracking, index) => {
                return (
                    <Box key={timeTracking.id}>
                        {index === 0 ? (
                            <>
                                <Box
                                    w="10px"
                                    h="10px"
                                    bg="#5D0E8B"
                                    borderRadius="full"
                                />
                                {timeTrackings.length > 1 && (
                                    <Box
                                        w="2px"
                                        h="42px"
                                        bg="#5D0E8B"
                                        ml="4px"
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <Box
                                    w="10px"
                                    h="10px"
                                    bg="#898989"
                                    borderRadius="full"
                                />
                                {index !== timeTrackings.length - 1 ? (
                                    <Box
                                        w="2px"
                                        h="42px"
                                        bg="#898989"
                                        zIndex={-1}
                                        ml="4px"
                                    />
                                ) : null}
                            </>
                        )}
                    </Box>
                );
            })}
        </Box>
    );
}
