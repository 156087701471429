import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Spinner, useDisclosure } from "@chakra-ui/react";

import { useParams } from "react-router-dom";
import ResourceState from "../../containers/ResourceState";
import { useTimeTrackingStore } from "../../store/TimeTrackings";
import { DrawerComponent } from "../../components/DrawerComponent";
import Header from "../../components/Header";
import { LoadingSpinner } from "../../components/LoadingSpinner";

export default function HistoryPage() {
    const { resourceId } = useParams();

    const setTimeTrackings = useTimeTrackingStore(
        (state) => state.addApiTimeTrackings
    );

    const [loading, setIsLoading] = useState(true);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const timeTrackings = useTimeTrackingStore((state) => state.timeTrackings);

    const loadHistory = async () => {
        await setTimeTrackings(resourceId as string);
        setIsLoading(false);
    };

    useEffect(() => {
        loadHistory();
    }, []);

    if (loading) {
        return (
            <Flex
                w="full"
                h="calc(100vh)"
                alignItems="center"
                justifyContent="center"
            >
                <Spinner />
            </Flex>
        );
    }

    return (
        <>
            <Header title="Histórico de funcionamento" />
            <main data-testid="history-page-testid">
                {timeTrackings ? <ResourceState /> : <LoadingSpinner />}

                <Box marginEnd={5} marginStart={5}>
                    <Button
                        onClick={onOpen}
                        bg="#5D0E8B"
                        color="#fff"
                        width="full"
                    >
                        Atualizar
                    </Button>
                </Box>

                {isOpen && (
                    <DrawerComponent
                        resourceId={resourceId ? resourceId.toString() : ""}
                        onClose={onClose}
                        defaultState={0}
                        date={new Date().toString()}
                        isOpen={isOpen}
                        timetrackingId={undefined}
                    />
                )}
            </main>
        </>
    );
}
