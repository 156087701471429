import {
    ModalBody,
    FormControl,
    FormLabel,
    Input,
    Select,
    Button,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useStatesStore } from "../../../store/States";
import { useTypeLossStore } from "../../../store/TypeLoss";

export interface StateFormProps {
    description: string;
    type: number | undefined;
    code: string;
}

interface StateModalBodyProps {
    handleSubmit: (form: StateFormProps) => void;
}
export function StateModalBody({ handleSubmit }: StateModalBodyProps) {
    const { isEditing, isEditingPayload } = useStatesStore((state) => state);
    const [form, setForm] = useState<StateFormProps>({
        code:
            isEditingPayload && isEditingPayload.code
                ? isEditingPayload.code
                : "",
        description:
            isEditingPayload && isEditingPayload.description
                ? isEditingPayload.description
                : "",
        type: isEditingPayload ? isEditingPayload.type : undefined,
    });

    const { typesLoss } = useTypeLossStore((state) => state);
    return (
        <ModalBody>
            <FormControl mt={3}>
                <FormLabel as="legend">Descrição:</FormLabel>
                <Input
                    type="text"
                    defaultValue={form.description}
                    onChange={(e) => {
                        setForm({
                            ...form,
                            description: e.target.value,
                        });
                    }}
                    border="1px solid"
                    borderColor="#515151"
                    placeholder="Digite a descrição do estado..."
                    _placeholder={{ color: "#AEAEAE" }}
                    fontSize={[12, 14]}
                />
            </FormControl>
            <FormControl mt={3}>
                <FormLabel as="legend">Código:</FormLabel>
                <Input
                    type="text"
                    onChange={(e) => {
                        setForm({
                            ...form,
                            code: e.target.value,
                        });
                    }}
                    defaultValue={form.code}
                    border="1px solid"
                    borderColor="#515151"
                    placeholder="Digite o código do estado..."
                    _placeholder={{ color: "#AEAEAE" }}
                    fontSize={[12, 14]}
                />
            </FormControl>
            <FormControl mt={3}>
                <FormLabel as="legend">Tipo:</FormLabel>
                <Select
                    placeholder="Selecione o item"
                    _placeholder={{ color: "#AEAEAE" }}
                    onChange={(e) => {
                        setForm({
                            ...form,
                            type: Number(e.target.value),
                        });
                    }}
                    defaultValue={form.type}
                    border="1px solid"
                    borderColor="#515151"
                    fontSize={[12, 14]}
                >
                    {typesLoss.map((typeLoss) => (
                        <option value={typeLoss.type} key={typeLoss.type}>
                            {typeLoss.loss}
                        </option>
                    ))}
                </Select>
            </FormControl>
            <FormControl
                display="flex"
                alignItems="center"
                justifyContent="center"
                w="100%"
            >
                <Button
                    variant="outline"
                    width="full"
                    marginTop={8}
                    onClick={() => handleSubmit(form)}
                    border="1px solid"
                    borderColor="#0DC5B7"
                    borderRadius="8px"
                    fontSize={[12, 14]}
                    maxW={300}
                    _hover={{
                        background: "#0DC5B7",
                        color: "white",
                    }}
                    transition="0.2s ease-in-out"
                >
                    {isEditing ? "Editar" : "Adicionar"}
                </Button>
            </FormControl>
        </ModalBody>
    );
}
