import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CompleteGraph } from "../../containers/CompleteGraph";
import Header from "../../components/Header";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { Subheader } from "../../components/Subheader";
import { useOeeStore } from "../../store/Oee";
import { OeeFilters } from "../../containers/OeeFilters";

export function OeeChart() {
    const { apiGetOeeByDay, oeeByDay, loading } = useOeeStore((state) => state);
    const { resourceId } = useParams();

    const formatedOee: any = (oeeByDayToFormat: any) => {
        return oeeByDayToFormat.map((oee: any) => {
            return { ...oee, value: Number(oee.value.toFixed(2)) };
        });
    };

    useEffect(() => {
        apiGetOeeByDay({ id: Number(resourceId) });
    }, []);

    return (
        <>
            <Header title="OEE" />
            <Subheader title="OEE (Média móvel) x Datas" />
            {loading ? (
                <LoadingSpinner />
            ) : (
                <CompleteGraph
                    values={formatedOee(oeeByDay)}
                    title="OEE"
                    hasPercentage
                />
            )}
            <OeeFilters />
        </>
    );
}
