import create from "zustand";
import { getPareto } from "../../services/pareto-service/ParetoService";

interface ParetoProps {
    x: string;
    bar: number;
    line: number;
}
interface ParetoStoreProps {
    loading: boolean;
    pareto: ParetoProps[];
    setPareto: (pareto: ParetoProps[]) => void;
    apiGetPareto: (
        machineId: number,
        from: string,
        to: string
    ) => Promise<void>;
}

export const useParetoStore = create<ParetoStoreProps>((set) => ({
    loading: false,
    pareto: [] as ParetoProps[],
    setPareto: (pareto: ParetoProps[]) => {
        set((state) => ({
            ...state,
            pareto,
        }));
    },
    apiGetPareto: async (machineId, from, to) => {
        set({ loading: true });

        const response = await getPareto(machineId, from, to).finally(() =>
            set({ loading: false })
        );

        const { data } = response;

        set((state) => ({
            ...state,
            pareto: data,
        }));
    },
}));
